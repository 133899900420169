import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Table } from "antd";
import type { TableProps } from "antd";

export interface Sortable {
  position: number;
  uuid: string;
}

interface Props<T extends Sortable> extends TableProps {
  dataSource: T[];
  filteredDataSource: T[];
  columns: TableProps<T>["columns"];
  onDragStart?: () => void;
  onPositionUpdate: (params: {
    uuid: string;
    previousPosition: number;
    newPosition: number;
    newOrder: T[];
  }) => void;
  forceResetTrigger?: number;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 1000 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

/**
 *
 * @param dataSource Data to be displayed in the table.
 * @param columns Antd column definition. A draggable icon is added to the columns.
 * @param onPositionUpdate Called whenever an item in the table is moved. previousPosition represents the previous
 * position of the item moved. newPosition represents the current position of the item moved. previousOrder and newOrder
 * represent the previous and new order of all items in the list.
 * @param forceResetTrigger Change this prop to force table to be reset to original datasource
 * @param other Any other Antd table props are passed through
 * @constructor
 */
export function SortableTable<T extends Sortable>({
  dataSource,
  filteredDataSource,
  columns,
  onPositionUpdate,
  onDragStart,
  forceResetTrigger,
  ...other
}: Props<T>) {
  const [data, setDataSource] = useState(dataSource);

  useEffect(() => {
    setDataSource(dataSource);
  }, [forceResetTrigger]);

  columns.unshift({ key: "sort", width: 3, align: "center" });

  useEffect(() => {
    setDataSource(dataSource);
  }, [dataSource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setDataSource((previous) => {
      const activeIndex = previous.findIndex((i) => i.uuid === active.id);
      const activeT = previous[activeIndex];
      const overIndex = previous.findIndex((i) => i.uuid === over?.id);
      const newOrder = arrayMove(previous, activeIndex, overIndex);

      onPositionUpdate({
        uuid: activeT.uuid,
        previousPosition: activeIndex + 1,
        newPosition: overIndex + 1,
        newOrder,
      });

      return newOrder;
    });
  };

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <SortableContext
        // rowKey array
        items={data.map((i) => i.uuid)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          {...other}
          components={{
            body: { row: Row },
          }}
          rowKey="uuid"
          columns={columns}
          dataSource={data.filter(({ uuid }) =>
            filteredDataSource.some(
              (filteredDataSource) => filteredDataSource.uuid === uuid
            )
          )}
        />
      </SortableContext>
    </DndContext>
  );
}

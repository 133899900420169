import React, { useEffect, useState } from "react";
import {
  RequestStatus,
  useOrganizationFieldConfiguration,
} from "../../../../util/data_hooks";
import { FieldConfigurationForm } from "./FieldConfigurationForm";
import { TestingCard } from "./field_testing/TestingCard";
import { Alert, Flex, Skeleton, Splitter } from "antd";
import { FieldDefinitionWithUsage, Team } from "../../../../util/types";

interface Props {
  fieldDefinition: FieldDefinitionWithUsage;
  allTeams: Team[];
  fieldConfigurationUuid: string | null;
  refreshTrigger: number;
  refreshData: () => Promise<unknown>;
}

export function FieldConfiguration({
  fieldDefinition,
  fieldConfigurationUuid,
  allTeams,
  refreshTrigger,
  refreshData,
}: Props) {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [sizes, setSizes] = React.useState<(number | string)[]>(["50%", "50%"]);
  const {
    fieldConfiguration,
    isLoading,
    mutate: refreshField,
  } = useOrganizationFieldConfiguration(fieldConfigurationUuid);

  useEffect(() => {
    setRefreshLoading(true);
    refreshField().then(
      () => setRefreshLoading(false),
      () => setRefreshLoading(false)
    );
  }, [refreshTrigger]);

  if (isLoading || refreshLoading) return <Skeleton active />;

  const teamsUsingField =
    fieldDefinition.configuration_usage[fieldConfiguration.uuid];
  const teamsWithoutAutomations = allTeams.filter((team) => {
    return (
      teamsUsingField.includes(team.uuid) &&
      team.field_automations_available <= 0
    );
  });

  return (
    <>
      {!dataLoading &&
        !fieldConfiguration.automated &&
        teamsWithoutAutomations.map((team) => (
          <Alert
            key={`no-automations-alert-${team.uuid}`}
            message={`Automation is disabled because ${team.name} has no automations remaining. Please remove this team from the configuration to enable automation.`}
            type="warning"
            showIcon
            style={{ marginBottom: "1rem" }}
          />
        ))}
      <Flex vertical gap="middle">
        <Splitter onResize={setSizes}>
          <Splitter.Panel
            size={sizes[0]}
            resizable={true}
            style={{ marginRight: "0.5rem" }}
          >
            <FieldConfigurationForm
              fieldDefinition={fieldDefinition}
              fieldConfiguration={fieldConfiguration}
              refreshFieldConfiguration={async () => {
                setDataLoading(true);
                await refreshData();
                await refreshField();
                setDataLoading(false);
              }}
              noAutomationsRemainingForTeams={
                teamsWithoutAutomations.length > 0
              }
            />
          </Splitter.Panel>
          <Splitter.Panel size={sizes[1]} style={{ marginLeft: "0.5rem" }}>
            <TestingCard />
          </Splitter.Panel>
        </Splitter>
      </Flex>
    </>
  );
}

import React, { ReactElement } from "react";
import { Space, Table, Typography } from "antd";
import { AssociatedCRMRecord, Meeting, User } from "../../util/types";
import { getFormattedDate } from "../../helpers/date_helpers";
import { useLocalStorage } from "@uidotdev/usehooks";
import { MeetingStatus } from "./MeetingStatus";
import { EmailAvatarLabel } from "./EmailAvatarLabel";
import { CRMRecordTag } from "./CRMRecordTag";
import { Moment } from "moment";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { getFriendlyMeetingStatusLabel } from "../../helpers/label_maps";
import { ColumnsType } from "antd/es/table";
import { MeetingStatusType, MeetingType } from "../../util/enums";
import { MeetingTypeIcon } from "./MeetingTypeIcon";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import {
  useOrganizationAssociatedCRMRecords,
  useOrganizationUsers,
} from "../../util/data_hooks";

export enum MeetingsTableParam {
  Page = "page",
  PageSize = "page_size",
}

enum MeetingTableColumn {
  Users = "users",
  Topic = "topic",
  StartTime = "start_time",
  CRMRecordIds = "crm_record_ids",
  MeetingStatus = "meeting_status",
}

export type MeetingTableFilters = {
  [MeetingTableColumn.Users]: string[];
  [MeetingTableColumn.CRMRecordIds]: string[];
  [MeetingTableColumn.MeetingStatus]: MeetingStatusType[];
  team_uuids: string[];
  meeting_types: MeetingType[];
  date_range: NoUndefinedRangeValueType<Moment>;
};

export const DEFAULT_FILTERS: MeetingTableFilters = {
  users: null,
  team_uuids: null,
  crm_record_ids: null,
  meeting_status: null,
  date_range: null,
  meeting_types: null,
};

export const getFiltersCount = (filters: MeetingTableFilters): number =>
  filters && Object.values(filters).filter(Boolean).length;

interface MeetingsTableProps {
  isLoading: boolean;
  title?: ReactElement;
  meetings: Meeting[];
  totalMeetings: number;
  user: User;
  includeUserColumn?: boolean;
  savedFiltersKey: string;
  empty: ReactElement;
  onRowClick: (meetingUuid: string) => void;
}

export function MeetingsTable({
  isLoading,
  title = null,
  meetings,
  totalMeetings,
  user,
  includeUserColumn = false,
  savedFiltersKey,
  empty,
  onRowClick,
}: MeetingsTableProps) {
  const { users } = useOrganizationUsers();
  const { associatedCRMRecords } = useOrganizationAssociatedCRMRecords();

  const [savedFilters, setSavedFilters] = useLocalStorage<MeetingTableFilters>(
    savedFiltersKey,
    DEFAULT_FILTERS
  );

  const [page, setPage] = useSearchParamsState(MeetingsTableParam.Page, "1");
  const [pageSize, setPageSize] = useSearchParamsState(
    MeetingsTableParam.PageSize,
    "10"
  );

  const parsedPage = parseInt(page);
  const parsedPageSize = parseInt(pageSize);

  const columns: ColumnsType<Meeting> = [
    ...(includeUserColumn
      ? [
          {
            key: MeetingTableColumn.Users,
            title: "User",
            dataIndex: "user_email",
            render: (user_email: string) => (
              <EmailAvatarLabel email={user_email} />
            ),
            filteredValue: savedFilters[MeetingTableColumn.Users],
            filterSearch: true,
            filters: users.map(({ uuid, email }) => {
              return { text: email, value: uuid };
            }),
          },
        ]
      : []),
    {
      key: MeetingTableColumn.Topic,
      title: "Name",
      dataIndex: "topic",
      render: (topic, { meeting_type }) => (
        <Space style={{ alignItems: "start" }}>
          <MeetingTypeIcon meetingType={meeting_type} />
          <Typography.Text>{topic}</Typography.Text>
        </Space>
      ),
    },
    {
      key: MeetingTableColumn.StartTime,
      title: "Date & Time",
      dataIndex: "start_time",
      render: (start_time) => getFormattedDate(start_time),
    },
    {
      key: MeetingTableColumn.CRMRecordIds,
      title: "Associated CRM Records",
      dataIndex: "associated_crm_records",
      width: 250,
      render: (associatedCRMRecords: AssociatedCRMRecord[]) => {
        if (associatedCRMRecords.length > 0) {
          const { crm_record_name, synced_crm_object, auto_associated } =
            associatedCRMRecords[0];

          return (
            <Typography.Text>
              <CRMRecordTag
                autoAssociated={auto_associated}
                crm={user?.organization?.crm_provider_name}
                name={`${crm_record_name} (${synced_crm_object.crm_object_label})`}
              />

              <Typography.Text italic>
                {associatedCRMRecords.length > 1 &&
                  ` and ${associatedCRMRecords.length - 1} more`}
              </Typography.Text>
            </Typography.Text>
          );
        }

        return "-";
      },
      filteredValue: savedFilters[MeetingTableColumn.CRMRecordIds],
      filterSearch: true,
      filters: associatedCRMRecords.map(
        ({ crm_record_id, crm_record_name, synced_crm_object }) => {
          return {
            text: `${crm_record_name} (${synced_crm_object.crm_object_label})`,
            value: crm_record_id,
          };
        }
      ),
    },
    {
      key: MeetingTableColumn.MeetingStatus,
      title: "Status",
      align: "center",
      width: 100,
      render: (_, meeting: Meeting) => <MeetingStatus meeting={meeting} />,
      filteredValue: savedFilters[MeetingTableColumn.MeetingStatus],
      filters: Object.values(MeetingStatusType).map((value) => {
        return { text: getFriendlyMeetingStatusLabel(value), value };
      }),
    },
  ];

  return (
    <Table<Meeting>
      showHeader
      title={title ? () => title : null}
      rowKey={({ uuid }) => uuid}
      loading={isLoading}
      dataSource={meetings}
      columns={columns}
      locale={{ emptyText: empty }}
      onRow={({ uuid }) => {
        return {
          style: { cursor: "pointer" },
          onClick: () => onRowClick(uuid),
        };
      }}
      onChange={(_pagination, filters, _sorter, { action }) => {
        if (action === "filter") setPage("1");
        setSavedFilters({ ...savedFilters, ...filters } as MeetingTableFilters);
      }}
      scroll={{ x: 600 }}
      pagination={{
        current: parsedPage,
        pageSize: parsedPageSize,
        total: totalMeetings,
        onChange: (newPage, newPageSize) => {
          if (parsedPage !== newPage) {
            setPage(newPage.toString());
          } else {
            setPageSize(newPageSize.toString());
          }
        },
        pageSizeOptions: [10, 20, 50, 100],
        showSizeChanger: true,
      }}
    />
  );
}

import { Tooltip } from "antd";
import React, { useCallback, useMemo, useRef } from "react";
import { useEffect } from "react";
import ReactFlow, {
  addEdge,
  Background,
  ControlButton,
  Controls,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { getLayoutedWorkflowElements } from "./util/getLayoutedWorkflowElements";
import {
  getInitialWorkflowElements,
  REMOVEABLE_EDGE,
} from "./util/getInitialWorkflowElements";
import { WorkflowStateNode } from "./WorkflowStateNode";
import { WorkflowTransitionNode } from "./WorkflowTransitionNode";
import { useWorkflow } from "../../../util/data_hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import RemoveableEdge from "./RemoveableEdge";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { usePageTitle } from "../../hooks/usePageTitle";

export function WorkflowEditor() {
  const navigate = useNavigate();
  const { fitView } = useReactFlow();

  const { workflowUuid } = useParams();
  const {
    workflow,
    mutate: mutateWorkflow,
    isLoading,
  } = useWorkflow(workflowUuid);

  const nodesInitialized = useNodesInitialized();

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  usePageTitle(workflow?.name);

  const setDefaultLayout = () => {
    const { initialNodes, initialEdges } = getInitialWorkflowElements(workflow);

    const { nodes: layoutedNodes, edges: layoutedEdges } =
      getLayoutedWorkflowElements(initialNodes, initialEdges);

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    setTimeout(() => fitView({ maxZoom: 1 }), 5);
  };

  useEffect(() => {
    if (isLoading) return;
    setDefaultLayout();
  }, [isLoading, workflow]);

  useEffect(() => {
    if (nodesInitialized) fitView({ maxZoom: 1 });
  }, [nodes.length, edges.length, nodesInitialized]);

  const nodeTypes = useMemo(
    () => ({
      workflow_state: WorkflowStateNode,
      workflow_transition: WorkflowTransitionNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      removeableedge: RemoveableEdge,
    }),
    []
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnect = useCallback((params) => {
    setEdges((els) => addEdge({ ...params, type: REMOVEABLE_EDGE }, els));
    mutateWorkflow();
  }, []);

  return (
    <div
      ref={reactFlowWrapper}
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnectStart={onConnectStart}
        onConnect={onConnect}
        autoPanOnConnect
        fitViewOptions={{ maxZoom: 1 }}
        fitView
        deleteKeyCode={null}
      >
        <Outlet />
        <Controls
          position="top-left"
          showInteractive={false}
          onFitView={setDefaultLayout}
        >
          <Tooltip title="Exit Workflow Builder" placement="right">
            <ControlButton
              onClick={() =>
                navigate(`/dashboard/settings/workflows/${workflowUuid}`)
              }
            >
              <CloseCircleTwoTone />
            </ControlButton>
          </Tooltip>
        </Controls>
        <Background gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}

import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { useTeams } from "../../../../util/data_hooks";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setTeamFilterUuids } from "../../../redux/features/FieldConfigurationFilters/fieldConfigurationFilterSlice";

const parseTeamUuidsString = (teamUuids: string | null): string[] => {
  return teamUuids ? teamUuids.split(",") : [];
};

export function useFieldConfigurationsFilters() {
  const dispatch = useAppDispatch();
  const teamsHook = useTeams();
  const { teams } = teamsHook;
  const teamOptions = teams.map((team) => ({
    label: team.name,
    value: team.uuid,
  }));
  const [filterValue, setFilterValue] = useSearchParamsState(
    "filter_value",
    null
  );
  const [teamFilterValue, setTeamFilterValue] = useSearchParamsState(
    "team_filter",
    null
  );
  const teamFilterUuids = useAppSelector(
    (state) => state.fieldConfigurationFilter.teamFilterUuids
  );
  const dispatchTeamFilterUuids = (teamUuids: string[]) => {
    dispatch(setTeamFilterUuids(teamUuids));
  };

  useEffect(() => {
    if (teamFilterValue) {
      dispatchTeamFilterUuids(parseTeamUuidsString(teamFilterValue));
    }
  }, []);

  useEffect(() => {
    setTeamFilterValue(teamFilterUuids.join());
  }, [teamFilterUuids]);

  return {
    filterValue,
    setFilterValue,
    teamFilterUuids,
    dispatchTeamFilterUuids,
    teamOptions,
    ...teamsHook,
  };
}

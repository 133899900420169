export const WORKFLOW_VARIABLE_REGEX_MATCHER = /{{(.*?)}}/g;

export enum WorkflowVariableFormatType {
  Date = "format_date",
  Datetime = "format_datetime",
  FormatPicklist = "format_picklist",
  FormatNumber = "format_number",
}

export enum WorkflowVariableType {
  Action = "action",
  Datetime = "datetime",
}

export const parseVariable = (
  variable: string
): {
  formatType: WorkflowVariableFormatType;
  variableType: WorkflowVariableType;
  attributes: string[];
} => {
  if (!(typeof variable === "string")) {
    return { formatType: null, variableType: null, attributes: [] };
  }

  const matches = variable
    ? variable.match(WORKFLOW_VARIABLE_REGEX_MATCHER)
    : "";

  if (!matches?.length) {
    return { formatType: null, variableType: null, attributes: [] };
  }

  const parsedVariable = matches[0].replace(/^\{\{|\}\}$/g, "");

  const splitBySpace = parsedVariable.split(" ");
  const formatType =
    splitBySpace.length === 2
      ? (splitBySpace[0] as WorkflowVariableFormatType)
      : null;

  const variableAttributes = formatType ? splitBySpace[1] : parsedVariable;

  const variableType = variableAttributes.split(".")[0] as WorkflowVariableType;
  const attributes = variableAttributes.split(".").slice(1);

  return { formatType, variableType, attributes };
};

export const getFriendlyVariableName = (variable: string): string => {
  const { formatType, variableType, attributes } = parseVariable(variable);

  if (!variableType) return "";

  let name = "";

  switch (variableType) {
    case "action":
      name = attributes.slice(1).join(".");
      break;
    case "datetime":
      name = attributes.join(".");
      break;
    default:
      return "";
  }

  if (formatType) name += " (Formatted)";

  return name;
};

export const getFormatDescription = (variable: string): string => {
  const { formatType } = parseVariable(variable);

  switch (formatType) {
    case WorkflowVariableFormatType.Date:
      return `Date formatted. For example, timestamps will be rendered in the following format: "August 5th, 2024".`;
    case WorkflowVariableFormatType.Datetime:
      return `Date & time formatted. For example, timestamps will be rendered in the following format: "August 5th, 2024 at 11:00 AM CDT".`;
    case WorkflowVariableFormatType.FormatPicklist:
      return `Picklist formatted. Picklist options will be rendered using the associated label, rather than the raw value.`;
    case WorkflowVariableFormatType.FormatNumber:
      return `Number formatted. For example, number 150000 will be rendered as: "150,000"`;
    default:
      null;
  }
};

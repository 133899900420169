import React from "react";
import { Meeting } from "../../util/types";
import {
  CheckCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { Space, Tag, Typography } from "antd";
import { getFriendlyMeetingStatusLabel } from "../../helpers/label_maps";
import { MeetingStatusType } from "../../util/enums";

export function MeetingStatus({ meeting }: { meeting: Meeting }) {
  if (!meeting) return null;

  const status = meeting.status;

  if (status === MeetingStatusType.Analyzing) {
    return (
      <Space>
        <LoadingOutlined style={{ color: blue.primary }} spin />
        <Typography.Text>Analyzing...</Typography.Text>
      </Space>
    );
  }

  if (status === MeetingStatusType.Unreviewed) {
    return (
      <Tag icon={<InfoCircleOutlined />} color="blue">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }

  if (status === MeetingStatusType.Viewed) {
    return (
      <Tag icon={<EyeOutlined />}>{getFriendlyMeetingStatusLabel(status)}</Tag>
    );
  }

  if (status === MeetingStatusType.Synced) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }

  if (status === MeetingStatusType.AutoSynced) {
    return (
      <Tag icon={<SyncOutlined />} color="green">
        {getFriendlyMeetingStatusLabel(status)}
      </Tag>
    );
  }
}

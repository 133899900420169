import { useEffect, useState } from "react";
import { WorkflowState } from "../../../../util/types";
import { useWorkflowStates } from "../../../../util/data_hooks";

const getSourceWorkflowState = (
  workflowStates: WorkflowState[],
  workflowState: WorkflowState,
  appendUpstreamWorkflowStates: (workflowState: WorkflowState) => void
) => {
  const sourceTransition = workflowState?.source_workflow_transition;

  if (!sourceTransition) return;

  const sourceWorkflowState = workflowStates.find(
    ({ uuid }) => uuid === sourceTransition.workflow_state_uuid
  );

  appendUpstreamWorkflowStates(sourceWorkflowState);

  getSourceWorkflowState(
    workflowStates,
    sourceWorkflowState,
    appendUpstreamWorkflowStates
  );
};

export function useUpstreamWorkflowStates({
  workflowUuid,
  workflowStateUuid,
  includeCurrentWorkflowState = false,
}: {
  workflowUuid: string;
  workflowStateUuid: string;
  includeCurrentWorkflowState: boolean;
}): [boolean, WorkflowState[]] {
  const { workflowStates, isLoading } = useWorkflowStates(workflowUuid);

  const [upstreamWorkflowStates, appendUpstreamWorkflowStates] = useState([]);

  useEffect(() => {
    if (isLoading) return;

    const workflowState = workflowStates.find(
      ({ uuid }) => uuid === workflowStateUuid
    );

    if (upstreamWorkflowStates.length === 0) {
      getSourceWorkflowState(workflowStates, workflowState, (newState) =>
        appendUpstreamWorkflowStates((prevStates) => [newState, ...prevStates])
      );
    }

    if (includeCurrentWorkflowState) {
      appendUpstreamWorkflowStates((prevStates) => [
        workflowState,
        ...prevStates,
      ]);
    }
  }, [isLoading, workflowStates]);

  return [isLoading, upstreamWorkflowStates];
}

import React from "react";
import { Empty, Skeleton, Space, Typography } from "antd";
import { Integration, UserRole } from "../../../util/enums";
import { IntegrationCard, IntegrationCardProps } from "./IntegrationCard";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useMyIntegrations, useUser } from "../../../util/data_hooks";
import { IntegrationInfo } from "../../../util/types";
import _ from "lodash";

const getIntegrationCardProps = (
  integrations: IntegrationInfo[],
  updateIntegrations: () => void
): IntegrationCardProps[] => {
  return integrations.map((integration) => {
    const cardProps = {
      integration: integration,
      updateIntegrations: updateIntegrations,
    };
    switch (integration.name) {
      case Integration.Zoom:
        return _.merge(cardProps, {
          name: "Zoom",
          description: "Sync cloud recordings from Zoom",
        });
      case Integration.ZoomAdmin:
        return _.merge(cardProps, {
          adminManaged: true,
          name: "Zoom (Admin Managed)",
          description: "Sync cloud recordings from Zoom",
        });
      case Integration.Gong:
        return _.merge(cardProps, {
          adminManaged: true,
          name: "Gong",
          description: "Sync call recordings from Gong",
        });
      case Integration.Microsoft:
        return _.merge(cardProps, {
          adminManaged: true,
          name: "Microsoft Teams",
          description:
            "Send notifications or sync recorded meetings from Teams",
        });
      case Integration.Salesloft:
        return _.merge(cardProps, {
          adminManaged: true,
          name: "Salesloft",
          description: "Sync phone call recordings from Salesloft",
        });
      case Integration.Slack:
        return _.merge(cardProps, {
          name: "Slack",
          adminManaged: true,
          description:
            "Get notified when Swyft has finished analyzing a meeting",
        });
    }
  });
};

export function Integrations() {
  usePageTitle("Integrations");

  const {
    meetingIntegrations,
    notificationIntegrations,
    isLoading: isIntegrationsLoading,
    mutate: mutateIntegrations,
  } = useMyIntegrations();
  const { user, isLoading: isUserLoading } = useUser();

  if (isUserLoading || isIntegrationsLoading) return <Skeleton active />;

  const hasAdminRole = user.roles.includes(UserRole.Admin);

  const filteredIntegrations = getIntegrationCardProps(
    [...meetingIntegrations, ...notificationIntegrations],
    mutateIntegrations
  )
    .filter(Boolean)
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.integration.name === value.integration.name) // Dedupe integrations if they belong to multiple providers (i.e. Microsoft)
    )
    .filter(({ adminManaged }) => {
      if (!adminManaged) return true;
      return hasAdminRole;
    });

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <div>
        <Typography.Title level={3}>Integrations</Typography.Title>

        <Typography.Text>
          Click the connect button for the integrations you want to enable
        </Typography.Text>
      </div>

      <div>
        {filteredIntegrations.length > 0 ? (
          <>
            {filteredIntegrations.map((accountIntegration) => (
              <IntegrationCard
                key={accountIntegration.integration.name}
                {...accountIntegration}
              />
            ))}
          </>
        ) : (
          <Empty description="No Integrations Found" />
        )}
      </div>
    </Space>
  );
}

import { Button, Card, Col, message, Row, Space, Typography } from "antd";
import { useIntegrationWindow } from "../../hooks/useIntegrationWindow";
import React, { ReactElement, useContext, useState } from "react";
import { IntegrationLogo } from "../../shared/IntegrationLogo";
import { IntegrationInfo } from "../../../util/types";
import { getFriendlyIntegrationLabel } from "../../../helpers/label_maps";
import { IntegrationUserAssociationModal } from "./IntegrationUserAssociationModal";
import { Integration, UserRole } from "../../../util/enums";
import { AppContext } from "../../App";
import { useUser } from "../../../util/data_hooks";

export interface IntegrationCardProps {
  integration: IntegrationInfo;
  name: string;
  description: string | ReactElement;
  adminManaged?: boolean;
  updateIntegrations: () => void;
}

export function IntegrationCard({
  integration,
  name,
  description,
  adminManaged,
  updateIntegrations,
}: IntegrationCardProps) {
  const { user } = useUser();
  const context = useContext(AppContext);
  const isImpersonating = !!context.user_impersonation_email;

  const isSuperAdmin =
    user?.roles?.includes(UserRole.SuperAdmin) || isImpersonating;

  const [openIntegrationWindow] = useIntegrationWindow();

  const onClick = async () => {
    await openIntegrationWindow(integration.name);
    updateIntegrations();
    message.success(
      `${getFriendlyIntegrationLabel(integration.name)} successfully connected!`
    );
  };

  const [
    integrationUserAssociationModalOpen,
    setIntegrationUserAssociationModalOpen,
  ] = useState<boolean>(false);

  return (
    <>
      {adminManaged && (
        <IntegrationUserAssociationModal
          integration={integration.name}
          open={integrationUserAssociationModalOpen}
          setOpen={setIntegrationUserAssociationModalOpen}
        />
      )}

      <Card key={integration.name} style={{ marginBottom: 10 }}>
        <Row justify="space-between" align="middle" gutter={[0, 20]}>
          <Row align="middle" gutter={24} wrap={false}>
            <Col>
              <IntegrationLogo type={integration.name} />
            </Col>

            <Col>
              <Row gutter={8} align="middle" style={{ marginBottom: 8 }}>
                <Col>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    {name}
                  </Typography.Title>
                </Col>
              </Row>

              <Typography.Text type="secondary">{description}</Typography.Text>
            </Col>
          </Row>

          <Col>
            <Space>
              {!isImpersonating && (
                <Button
                  type={integration.connected ? "primary" : "default"}
                  onClick={onClick}
                >
                  {integration.connected ? "Connected" : "Connect"}
                </Button>
              )}

              {adminManaged &&
                integration.connected &&
                [
                  Integration.Gong,
                  Integration.Slack,
                  Integration.Microsoft,
                ].includes(integration.name) &&
                isSuperAdmin && (
                  <Button
                    onClick={() => setIntegrationUserAssociationModalOpen(true)}
                  >
                    Manage Users
                  </Button>
                )}
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
}

import React from "react";
import { Button, Empty } from "antd";

interface Props {
  onCreate: () => void;
}
export function EmptyFieldConfiguration({ onCreate }: Props) {
  return (
    <Empty description={"No Field Configurations"}>
      <Button type="primary" onClick={onCreate}>
        Create
      </Button>
    </Empty>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FieldConfigurationFilterSlice {
  teamFilterUuids: string[];
}

const initialState: FieldConfigurationFilterSlice = {
  teamFilterUuids: [],
};

export const fieldConfigurationFilterSlice = createSlice({
  name: "fieldConfigurationFilter",
  initialState,
  reducers: {
    setTeamFilterUuids: (state, action: PayloadAction<string[]>) => {
      state.teamFilterUuids = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTeamFilterUuids } = fieldConfigurationFilterSlice.actions;

export default fieldConfigurationFilterSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  TablePaginationConfig,
  Tooltip,
  Typography,
  message,
  Tag,
} from "antd";
import type { TableProps } from "antd";
import { EyeOutlined, ThunderboltOutlined } from "@ant-design/icons";
import {
  FieldConfigurationGroup,
  FieldDefinitionPreview,
  FieldConfigurationTeamUsage,
  Team,
} from "../../../util/types";
import { useNavigate } from "react-router-dom";
import { CRMIcon, getCRMColor } from "../../shared/CRMIcon";
import { SwyftIcon } from "../../shared/SwyftIcon";
import {
  deactivateFieldConfigurationGroup,
  refreshOrganizationCRMFields,
} from "../../../util/api";
import { FieldDefinitionEditorModal } from "./FieldDefinitionEditorModal";
import { SearchableTable } from "../../shared/SearchableTable";
import { MoreOutlined } from "@ant-design/icons";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import _ from "lodash";
import { useFieldConfigurationsFilters } from "./filters/useFieldConfigurationsFilters";

const DEFAULT_PAGE_SIZE = 10;

const sanitizeFilterValue = (value: string): string =>
  (value?.toLocaleLowerCase() || "").split(" ").join("");

const teamTagInfo = (
  teams: Team[],
  teamColors: { [teamUuid: string]: string },
  teamUsage: FieldConfigurationTeamUsage
): {
  [team_name: string]: {
    visible: boolean;
    automated: boolean;
    color: string;
  };
} => {
  const teamTagInfo = {};

  teams.forEach((team) => {
    const isVisible = teamUsage.visible.includes(team.uuid);
    const isAutomated = teamUsage.automated.includes(team.uuid);

    if (isVisible || isAutomated) {
      teamTagInfo[team.name] = {
        visible: teamUsage.visible.includes(team.uuid),
        automated: teamUsage.automated.includes(team.uuid),
        color: teamColors[team.uuid],
      };
    }
  });

  return teamTagInfo;
};

interface FieldConfigurationsTable {
  teams: Team[];
  teamColors: { [teamUuid: string]: string };
  fieldConfigurationGroup: FieldConfigurationGroup;
  onSave: () => void;
}

export function FieldConfigurationsTable({
  teams,
  teamColors,
  fieldConfigurationGroup,
  onSave,
}: FieldConfigurationsTable) {
  const navigate = useNavigate();

  const { filterValue, teamFilterUuids } = useFieldConfigurationsFilters();
  const [filteredFieldDefinitions, setfilteredFieldDefinitions] = useState<
    FieldDefinitionPreview[]
  >(fieldConfigurationGroup.field_definitions);
  const [isCreateFieldConfigModalOpen, setCreateFieldConfigModalOpen] =
    useState(false);

  const [page, setPage] = useSearchParamsState(
    `${fieldConfigurationGroup.name}_page`,
    "0"
  );

  const [pageSize, setPageSize] = useSearchParamsState(
    `${fieldConfigurationGroup.name}_page_size`,
    DEFAULT_PAGE_SIZE.toString()
  );

  const [crmFieldsRefreshing, setCRMFieldsRefreshing] =
    useState<boolean>(false);

  const syncOrganizationCRMFields = async () => {
    setCRMFieldsRefreshing(true);

    try {
      await refreshOrganizationCRMFields();
      message.success(`Fields Resynced Successfully`);
    } catch (e) {
      message.error(
        `An error occurred while resyncing fields. Please try again.`
      );
    }

    setCRMFieldsRefreshing(false);
  };

  useEffect(() => {
    const filteredResults = fieldConfigurationGroup.field_definitions.filter(
      ({ name, label, team_usage }) => {
        if (!filterValue && teamFilterUuids.length === 0) return true;

        const combinedUsages = _.union(
          team_usage.visible,
          team_usage.automated
        );
        const containsTeam =
          teamFilterUuids.length === 0 ||
          teamFilterUuids.every((teamUuid) =>
            combinedUsages.includes(teamUuid)
          );

        const sanitizedFilterValue = sanitizeFilterValue(filterValue);
        const sanitizedName = sanitizeFilterValue(name);
        const sanitizedLabel = sanitizeFilterValue(label);

        return (
          containsTeam &&
          (sanitizedLabel.includes(sanitizedFilterValue) ||
            sanitizedName.includes(sanitizedFilterValue))
        );
      }
    );

    setfilteredFieldDefinitions(filteredResults);
  }, [fieldConfigurationGroup, filterValue, teamFilterUuids]);

  const columns: TableProps<FieldDefinitionPreview>["columns"] = [
    {
      title: "Name",
      dataIndex: "label",
      width: "15%",
    },
    {
      title: "Field Type",
      dataIndex: "field_type",
      width: "15%",
    },
    {
      title: "Team Usage",
      dataIndex: "team_usage",
      width: "50%",
      render: (teamUsage: FieldConfigurationTeamUsage) => {
        const tagInfo = teamTagInfo(teams, teamColors, teamUsage);

        return (
          <Space>
            {Object.keys(tagInfo).map((teamName) => {
              const { visible, automated, color } = tagInfo[teamName];

              if (visible && automated) {
                return (
                  <Tooltip key={teamName} title={"Visible and Automated"}>
                    <Tag color={color}>
                      {teamName} <ThunderboltOutlined />
                      <EyeOutlined />
                    </Tag>
                  </Tooltip>
                );
              } else if (visible) {
                return (
                  <Tooltip key={teamName} title={"Visible"}>
                    <Tag color={color}>
                      {teamName} <EyeOutlined />
                    </Tag>
                  </Tooltip>
                );
              } else if (automated) {
                return (
                  <Tooltip key={teamName} title={"Automated"}>
                    <Tag color={color}>
                      {teamName} <ThunderboltOutlined />
                    </Tag>
                  </Tooltip>
                );
              }
            })}
          </Space>
        );
      },
    },
  ];

  const pagination: TablePaginationConfig = {
    current: parseInt(page) + 1,
    pageSize: parseInt(pageSize),
    total: filteredFieldDefinitions.length,
    onChange: (newPage, newPageSize) => {
      if (parseInt(page) !== newPage - 1) {
        setPage((newPage - 1).toString());
      } else {
        setPageSize(newPageSize.toString());
      }
    },
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true,
  };

  return (
    <>
      <FieldDefinitionEditorModal
        // team={null}
        open={isCreateFieldConfigModalOpen}
        fieldConfigurationGroup={fieldConfigurationGroup}
        // fieldConfigurationGroups={fieldConfigurationGroups}
        setOpen={setCreateFieldConfigModalOpen}
        onCreate={(fieldConfiguration) => {
          onSave();
          navigate(fieldConfiguration.uuid);
        }}
      />

      <Row align="middle" gutter={8}>
        <Col>
          <Row align="middle" gutter={[8, 0]}>
            <Col>
              {fieldConfigurationGroup.synced_crm_object?.crm_provider_name ? (
                <CRMIcon
                  crm={
                    fieldConfigurationGroup.synced_crm_object.crm_provider_name
                  }
                  color={getCRMColor(
                    fieldConfigurationGroup.synced_crm_object.crm_provider_name
                  )}
                  style={{ height: 25 }}
                />
              ) : (
                <SwyftIcon inverted width={25} />
              )}
            </Col>

            <Col>
              <Typography.Title level={4} style={{ margin: "20px 0px" }}>
                {fieldConfigurationGroup.label}
              </Typography.Title>
            </Col>
          </Row>
        </Col>

        <Col>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                fieldConfigurationGroup.synced_crm_object && {
                  key: "resync-fields",
                  label: (
                    <a onClick={syncOrganizationCRMFields}>Resync Fields</a>
                  ),
                },
                !fieldConfigurationGroup.synced_crm_object && {
                  key: "create-field",
                  label: (
                    <a onClick={() => setCreateFieldConfigModalOpen(true)}>
                      Create Custom Automation
                    </a>
                  ),
                },
                {
                  key: "deactivate-group",
                  danger: true,
                  label: (
                    <a
                      onClick={async () => {
                        try {
                          await deactivateFieldConfigurationGroup({
                            fieldConfigurationGroupUuid:
                              fieldConfigurationGroup.uuid,
                          });

                          message.success(
                            `"${fieldConfigurationGroup.label}"  removed`
                          );

                          onSave();
                        } catch (err) {
                          if (_.get(err, "response.status", 500) < 500) {
                            message.error(
                              _.get(
                                err,
                                "response.data.message",
                                "Oops, something is wrong on our end! Please try again later."
                              )
                            );
                          } else {
                            message.error(
                              `Oops! Something went wrong. Please try again.`
                            );
                          }
                        }
                      }}
                    >
                      Remove Group
                    </a>
                  ),
                },
              ],
            }}
          >
            <Button
              id={`field-configurations-dropdown-${fieldConfigurationGroup.name}`}
              loading={crmFieldsRefreshing}
              icon={<MoreOutlined />}
              size="small"
              shape="circle"
            />
          </Dropdown>
        </Col>
      </Row>

      <SearchableTable<FieldDefinitionPreview>
        id={`field-configurations-table-${fieldConfigurationGroup.name}`}
        rowKey={"uuid"}
        dataSource={fieldConfigurationGroup.field_definitions}
        filteredDataSource={filteredFieldDefinitions}
        columns={columns}
        pagination={pagination}
        onRow={({ uuid }) => {
          const field = fieldConfigurationGroup.field_definitions.find(
            (fieldConfiguration) => fieldConfiguration.uuid === uuid
          );

          if (!field) return null;

          if (field.active) {
            return {
              style: { cursor: "pointer" },
              onClick: () => {
                navigate(field.uuid);
              },
            };
          } else {
            return {
              style: {
                background: "#f0f0f0",
                cursor: "not-allowed",
                "&:hover": {
                  background: "#f0f0f0",
                },
              },
            };
          }
        }}
        locale={{
          emptyText: filterValue ? `No results for "${filterValue}"` : null,
        }}
      />
    </>
  );
}

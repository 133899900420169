import React, { useEffect, useState } from "react";
import { Col, Row, Select, Spin, Tag, Typography } from "antd";
import { getFriendlyVariableName } from "../../../util/helpers";
import { useUpstreamWorkflowStates } from "../../../util/useUpstreamWorkflowStates";
import { useParams } from "react-router-dom";
import { CRMFieldType, WorkflowActionType } from "../../../../../../util/enums";
import { blue } from "@ant-design/colors";

const generateColor = (string: string): string => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  let charIndexSum = 0;

  string.split("").forEach((char) => {
    charIndexSum += alphabet.indexOf(char.toLowerCase());
  });

  const colors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  const index = charIndexSum % colors.length;

  return colors[index];
};

const getKeywordMatchLikelihood = (
  filter: string = "",
  attribute: { description: string; path: string }
): number => {
  if (filter.length === 0) return 1;

  const filterValues = filter.toLowerCase().split(" ");

  const keywords = [
    ...attribute.description.toLowerCase().split(/[^a-zA-Z0-9]+/),
    ...attribute.path.toLowerCase().split(/[^a-zA-Z0-9]+/),
  ].filter(Boolean);

  const matches = [
    ...new Set(keywords.filter((value) => filterValues.includes(value))),
  ];

  return matches.length / keywords.length;
};

export const flattenArray = (
  inputArray
): {
  path: string;
  type: CRMFieldType;
  description: string;
  crmObjectType: string;
  tags: string[];
}[] => {
  const results: {
    path: string;
    type: CRMFieldType;
    description: string;
    crmObjectType: string;
    tags: string[];
  }[] = [];

  const recurse = (current: any, path: string) => {
    if (current?.type && Object.values(CRMFieldType).includes(current.type)) {
      results.push({
        path,
        type: current.type,
        description: current.description,
        crmObjectType: current.crm_object_type,
        tags: current.tags,
      });
    } else if (Array.isArray(current)) {
      current.forEach((item) => recurse(item, `${path}`));
    } else if (typeof current === "object" && current !== null) {
      Object.keys(current).forEach((key) => {
        recurse(current[key], path ? `${path}.${key}` : key);
      });
    }
  };

  recurse(inputArray, "");
  return results;
};

interface DynamicVariableSelectProps {
  value?: string;
  isMultiSelect?: boolean;
  staticOptions?: { label: string; value: string }[];
  fieldType?: CRMFieldType;
  crmObjectType?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
}

export function DynamicVariableSelect({
  value,
  isMultiSelect = false,
  staticOptions = [],
  fieldType,
  crmObjectType,
  autoFocus = false,
  onChange,
}: DynamicVariableSelectProps) {
  const { workflowUuid, workflowStateUuid } = useParams();

  const [upstreamWorkflowStatesLoading, upstreamWorkflowStates] =
    useUpstreamWorkflowStates({
      workflowUuid,
      workflowStateUuid,
      includeCurrentWorkflowState:
        window.location.pathname.includes("/new") ||
        window.location.pathname.includes("/workflow-transitions"),
    });

  const [filterValue, setFilterValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const dropdownOptions = upstreamWorkflowStates
    .map(({ uuid, name, action_type, response_attributes }) => {
      const flattenedResponseAttributes = flattenArray(response_attributes);
      const filteredOptions = flattenedResponseAttributes
        .filter(({ type }) => {
          if (!fieldType) return true;
          return type === fieldType;
        })
        .filter((attribute) => {
          if (!crmObjectType || !attribute.crmObjectType) return true;
          return attribute.crmObjectType === crmObjectType;
        });
      if (filteredOptions.length === 0) return;

      return {
        label: `Action: ${name}`,
        options: filteredOptions.map(({ path, description, tags }) => {
          return {
            key: `${uuid}-${path}`,
            label: path,
            value: `{{action.${uuid}.${path}}}`,
            actionType: action_type,
            path,
            workflowStateName: name,
            description,
            tags,
          };
        }),
      };
    })
    .filter(Boolean);

  const flatOptions = dropdownOptions.flatMap(({ options }) => options);

  const filteredFlatOptions = flatOptions
    .filter((option) => {
      if (!filterValue) return true;
      return getKeywordMatchLikelihood(filterValue, option) > 0;
    })
    .sort(
      (a, b) =>
        getKeywordMatchLikelihood(filterValue, b) -
        getKeywordMatchLikelihood(filterValue, a)
    );

  const filteredStaticOptions = staticOptions.filter((option) => {
    if (!filterValue) return true;

    return (
      option.label.toLowerCase().includes(filterValue.toLowerCase()) ||
      option.value.toLowerCase().includes(filterValue.toLowerCase())
    );
  });

  useEffect(() => {
    if (flatOptions.length === 1) onChange(flatOptions[0].value);
  }, [dropdownOptions]);

  useEffect(() => {
    if (autoFocus) setFocused(true);
  }, [autoFocus]);

  return (
    <Select
      ref={(input) => input && focused && input.focus()}
      defaultOpen={autoFocus}
      onBlur={() => setFocused(false)}
      allowClear
      labelRender={({ label, value }) => {
        const friendlyVariableName = getFriendlyVariableName(value as string);
        const data = flatOptions.find((o) => o.value === value);

        if (friendlyVariableName?.length > 0) {
          let dynamicVariable = "";

          if (data && data.actionType !== WorkflowActionType.Trigger) {
            dynamicVariable += `${data.workflowStateName}: `;
          }

          dynamicVariable += `{{${friendlyVariableName}}}`;

          return (
            <Typography.Text
              strong
              style={{ backgroundColor: blue[0], padding: 2, borderRadius: 2 }}
            >
              {dynamicVariable}
            </Typography.Text>
          );
        } else {
          return label;
        }
      }}
      optionRender={({ label, data }) => {
        if ("description" in data && data.description) {
          return (
            <Row gutter={[0, 8]} style={{ margin: "10px 0px" }}>
              {data.actionType !== WorkflowActionType.Trigger && (
                <Col span={24}>
                  <Typography.Text
                    type="secondary"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      fontWeight: 600,
                    }}
                  >
                    Action: {data.workflowStateName}
                  </Typography.Text>
                </Col>
              )}

              <Col span={24}>
                <Typography.Text
                  style={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {data.description}
                </Typography.Text>
              </Col>

              <Col span={24}>
                <Typography.Text
                  type="secondary"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  {`{{${data.path}}}`}
                </Typography.Text>
              </Col>

              {data.tags && (
                <Col span={24}>
                  {data.tags.map((tag, i) => (
                    <Tag key={i} color={generateColor(tag)}>
                      {tag}
                    </Tag>
                  ))}
                </Col>
              )}
            </Row>
          );
        } else {
          return label;
        }
      }}
      loading={upstreamWorkflowStatesLoading}
      style={{ width: "100%" }}
      value={value}
      placeholder={isMultiSelect ? "Select variables" : "Select a variable"}
      mode={isMultiSelect ? "multiple" : null}
      showSearch
      filterOption={false}
      onSearch={setFilterValue}
      onChange={onChange}
      notFoundContent={
        upstreamWorkflowStatesLoading ? (
          <Spin size="small" style={{ padding: 8, width: "100%" }} />
        ) : (
          <Typography.Text>No results found.</Typography.Text>
        )
      }
      options={[
        ...(filteredStaticOptions.length
          ? [
              {
                label: "Default Options",
                options: filteredStaticOptions,
              },
            ]
          : []),
        ...(filterValue ? filteredFlatOptions : dropdownOptions || []),
      ]}
    />
  );
}

import React from "react";
import { Badge, Button, Input, Popover, Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { teamSelectTagRender } from "../../../../shared/teamSelectTagRender";
import { usePlaygroundMeetingFilter } from "./usePlaygroundMeetingFilter";

export function MeetingFilter() {
  const {
    open,
    setOpen,
    filterCount,
    updateMeetingNameFilter,
    meetingNameFilter,
    teamsLoading,
    assignedTeamColors,
    teamOptions,
    updateTeamFilterUuids,
    teamFilterUuids,
  } = usePlaygroundMeetingFilter();

  const filterContent = (
    <div style={{ width: "25rem" }}>
      <Input
        placeholder={"Filter by meeting name"}
        style={{ marginBottom: "0.25rem" }}
        onChange={(input) => {
          updateMeetingNameFilter(input.target.value);
        }}
        allowClear
        value={meetingNameFilter}
      />
      <Select
        tagRender={teamSelectTagRender(assignedTeamColors)}
        style={{ width: "100%", marginRight: "0.5rem" }}
        mode="multiple"
        size="middle"
        placeholder="Filter by teams"
        allowClear
        loading={teamsLoading}
        options={teamOptions}
        onChange={updateTeamFilterUuids}
        value={teamFilterUuids}
      />
    </div>
  );
  return (
    <Popover
      content={filterContent}
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
      trigger="click"
      placement={"topRight"}
    >
      <Badge count={filterCount} color={"blue"}>
        <Button icon={<FilterOutlined />} />
      </Badge>
    </Popover>
  );
}

import { createTeam, updateTeam } from "../../../util/api";
import { get } from "lodash";
import { MeetingType } from "../../../util/enums";
import { getFriendlyMeetingTypeLabel } from "../../../helpers/label_maps";
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Switch,
  type SelectProps,
  Descriptions,
  Typography,
  Flex,
} from "antd";
import {
  useOrganizationCrmRoles,
  useOrganizationUsers,
} from "../../../util/data_hooks";
import { CrmRole, Organization, Team, User } from "../../../util/types";
import { useEffect, useState } from "react";
import React from "react";
import { ExportOutlined } from "@ant-design/icons";

interface TeamEditorFormProps {
  team?: Team;
  organization: Organization;
  afterSave: () => void;
}

export function TeamEditorForm({
  team,
  organization,
  afterSave,
}: TeamEditorFormProps) {
  const [form] = Form.useForm();

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const { crmRoles: organizationCrmRoles, isLoading: crmRolesLoading } =
    useOrganizationCrmRoles(organization);
  const { users: organizationUsers, isLoading: usersLoading } =
    useOrganizationUsers();

  const crmRolesToOptions = (
    roles: CrmRole[],
    selectedRoles: CrmRole[]
  ): SelectProps["options"] => {
    return roles.map((role) => ({
      label: role.name,
      value: role.uuid,
      disabled: !!selectedRoles.find((sr) => sr.uuid === role.uuid),
    }));
  };

  const usersToOptions = (
    allUsers: User[],
    selectedUsers: User[]
  ): SelectProps["options"] => {
    return allUsers.map((user) => ({
      label: user.email,
      value: user.uuid,
      disabled: !!selectedUsers.find((su) => su.uuid === user.uuid),
    }));
  };

  useEffect(() => {
    if (team) {
      form.setFieldsValue({
        ...team,
        user_uuids: usersToOptions(team.users, team.users),
        crm_roles: crmRolesToOptions(team.crm_roles, team.crm_roles),
      });
    } else {
      form.resetFields();
    }
  }, [team]);

  const handleSave = async (values) => {
    setFormSubmitting(true);

    try {
      const payload: Pick<Team, "name" | "is_default" | "meeting_types"> & {
        crm_role_uuids?: string[];
        user_uuids?: string[];
      } = {
        name: values.name,
        is_default: values.is_default || false,
        crm_role_uuids: values.crm_roles,
        user_uuids: values.user_uuids,
        meeting_types: values.meeting_types,
      };

      team ? await updateTeam(team.uuid, payload) : await createTeam(payload);

      afterSave();

      message.success(team ? "Team saved" : "Team created");
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";
      message.error(get(err, "response.data.error", defaultMessage));
    }

    setFormSubmitting(false);
  };

  return (
    <Form
      onFinish={handleSave}
      layout="vertical"
      form={form}
      style={{ marginTop: "1rem" }}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Enter a name" />
      </Form.Item>

      <Form.Item label="Is Default" name="is_default" valuePropName="checked">
        <Switch disabled={team?.is_default} style={{ marginRight: "0.5rem" }} />
      </Form.Item>

      <Form.Item label="Meeting Types" name="meeting_types">
        <Select
          id={"meeting_types"}
          mode="multiple"
          placeholder="Enabled meeting types"
          options={Object.values(MeetingType).map((value) => {
            return {
              label: getFriendlyMeetingTypeLabel(value),
              value,
            };
          })}
        />
      </Form.Item>

      {organization.teams_managed_by_crm ? (
        <Form.Item label="CRM Roles" name="crm_roles">
          <Select
            id={"crm_roles"}
            mode="multiple"
            optionFilterProp="label"
            placeholder="Add CRM roles to team"
            options={crmRolesToOptions(
              organizationCrmRoles,
              team?.crm_roles || []
            )}
            loading={crmRolesLoading}
          />
        </Form.Item>
      ) : (
        <Form.Item label="Users" name="user_uuids">
          <Select
            id={"users"}
            mode="multiple"
            placeholder="Search for users to add to team"
            showSearch
            optionFilterProp="label"
            loading={usersLoading}
            options={usersToOptions(organizationUsers, team?.users || [])}
          />
        </Form.Item>
      )}

      <Flex justify="space-between">
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFormSubmitting}>
            Save
          </Button>
        </Form.Item>
        {team && (
          <Descriptions style={{ width: "11rem" }}>
            <Descriptions.Item label="Automations">
              <Typography.Link
                italic
                href={`/dashboard/settings/field-configurations?team_filter=${team.uuid}`}
                target={"_blank"}
              >
                {`${team.field_automations_allocated} / ${team.field_automations_available + team.field_automations_allocated}  `}
                <ExportOutlined />
              </Typography.Link>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Flex>
    </Form>
  );
}

import { Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

export function TruncatedContent({
  children,
  maxHeight,
}: {
  children: any;
  maxHeight: number;
}) {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    setIsTruncated(contentRef.current.scrollHeight > maxHeight);
  }, [contentRef?.current?.scrollHeight, children]);

  const toggleContent = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : maxHeight,
          overflow: "hidden",
          position: "relative",
          transition: "max-height 0.3s ease-in-out",
        }}
      >
        {children}

        {isTruncated && !isExpanded && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 100,
              backgroundImage:
                "linear-gradient(to top, white, rgba(255, 255, 255, 0))",
            }}
          />
        )}
      </div>

      {isTruncated && (
        <Row justify="center" style={{ marginTop: 15 }}>
          <Typography.Link strong onClick={toggleContent}>
            {isExpanded ? "Show Less" : "Show More"}
          </Typography.Link>
        </Row>
      )}
    </div>
  );
}

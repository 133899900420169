import React, { useEffect, useState } from "react";
import { Input, Button, Card, Space, Typography, Tooltip, Form } from "antd";
import { CloudUploadOutlined, InfoCircleOutlined } from "@ant-design/icons";

interface Props {
  label: string;
  hint?: string;
  context: string;
  loading: boolean;
  maxContextLength?: number;
  onSave: (value: string) => void;
}

export function ContextData({
  label,
  context,
  loading,
  onSave,
  hint,
  maxContextLength = 3000,
}: Props) {
  const [form] = Form.useForm();
  const [isFormReset, setFormReset] = useState(true);

  useEffect(() => {
    setFormReset(true);
    form.setFieldValue("context", context);
  }, [context]);

  const saveForm = async (values: { context: string }) => {
    onSave(values.context);
  };

  const resetForm = () => {
    form.resetFields();
    setFormReset(true);
  };

  return (
    <Form
      onFinish={saveForm}
      form={form}
      initialValues={{ context }}
      onValuesChange={() => setFormReset(false)}
      name={`context-form-${label}`}
      layout={"vertical"}
    >
      <Card
        title={
          <Space>
            <Typography.Title level={5} style={{ marginTop: "0.5rem" }}>
              {label}
            </Typography.Title>
            <div style={{ width: "100%" }}>
              <Tooltip title={hint}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </Space>
        }
        extra={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={resetForm}
              style={{ marginRight: "0.5rem", marginLeft: "auto" }}
            >
              Reset Changes
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={isFormReset}
              icon={<CloudUploadOutlined />}
            >
              Commit and Save
            </Button>
          </div>
        }
      >
        <Form.Item name="context" rules={[{ max: maxContextLength }]}>
          <Input.TextArea rows={6} placeholder={hint} />
        </Form.Item>
      </Card>
    </Form>
  );
}

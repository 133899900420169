import React from "react";
import { Button, Space, Tooltip, Typography } from "antd";
import { AutoGeneratedField, CRMField, CRMRecord } from "../../../util/types";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { green, red } from "@ant-design/colors";

interface AutoGeneratedFieldCardActionsProps {
  autoGeneratedField: AutoGeneratedField;
  changesDetected: boolean;
  crmField: CRMField;
  crmRecord: CRMRecord;
  handleAutoGeneratedFieldUpdate: (
    attributes: Partial<AutoGeneratedField>
  ) => void;
}

export function AutoGeneratedFieldCardActions({
  autoGeneratedField: { accepted },
  changesDetected,
  crmField,
  crmRecord,
  handleAutoGeneratedFieldUpdate,
}: AutoGeneratedFieldCardActionsProps) {
  if (!changesDetected || !crmField) return null;

  if (accepted === null) {
    return (
      <Space>
        <Tooltip
          title={crmRecord?.crm_record_id ? null : "Please select a CRM record"}
        >
          <Button
            disabled={!crmRecord?.crm_record_id}
            size="small"
            onClick={() => handleAutoGeneratedFieldUpdate({ accepted: true })}
            type="primary"
          >
            Approve Changes
          </Button>
        </Tooltip>

        <Button
          size="small"
          danger
          onClick={() => handleAutoGeneratedFieldUpdate({ accepted: false })}
        >
          Reject Changes
        </Button>
      </Space>
    );
  }

  return (
    <Space>
      {accepted ? (
        <Space>
          <Typography.Text>Changes Approved</Typography.Text>

          <CheckCircleTwoTone twoToneColor={green["primary"]} />
        </Space>
      ) : (
        <Space>
          <Typography.Text>Changes Rejected</Typography.Text>

          <CloseCircleTwoTone twoToneColor={red["primary"]} />
        </Space>
      )}

      <Typography.Text>|</Typography.Text>

      <Typography.Link
        onClick={() => handleAutoGeneratedFieldUpdate({ accepted: null })}
      >
        Undo
      </Typography.Link>
    </Space>
  );
}

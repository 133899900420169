import { Col, Input, Row, Select } from "antd";
import React from "react";
import { useTeams } from "../../util/data_hooks";
import { MeetingsTableParam, MeetingTableFilters } from "./MeetingsTable";
import { getFriendlyMeetingTypeLabel } from "../../helpers/label_maps";
import { MeetingType } from "../../util/enums";
import DatePicker from "../shared/DatePicker";
import moment from "moment";
import { useSearchParamsState } from "../hooks/useSearchParamsState";

export function MeetingFilters({
  showTeamsFilter = true,
  savedFilters,
  meetingName,
  setSavedFilters,
  setMeetingName,
}: {
  showTeamsFilter?: boolean;
  savedFilters: MeetingTableFilters;
  meetingName: string;
  setSavedFilters: (filters: MeetingTableFilters) => void;
  setMeetingName: (name: string) => void;
}) {
  const { teams } = useTeams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_currentPage, setPage] = useSearchParamsState(
    MeetingsTableParam.Page,
    "1"
  );

  return (
    <Row gutter={[6, 6]}>
      <Col span={12}>
        <Select
          style={{ width: "100%" }}
          mode="multiple"
          size="middle"
          placeholder="Call types"
          allowClear
          options={[
            ...Object.values(MeetingType).map((meetingType) => {
              return {
                label: getFriendlyMeetingTypeLabel(meetingType),
                value: meetingType,
              };
            }),
          ]}
          onChange={(types: MeetingType[]) => {
            setSavedFilters({
              ...savedFilters,
              meeting_types: types.length ? types : null,
            });
            setPage("1");
          }}
          value={savedFilters?.meeting_types}
        />
      </Col>

      <Col span={12}>
        <DatePicker.RangePicker
          style={{ width: "100%" }}
          onChange={(dates) => {
            setSavedFilters({ ...savedFilters, date_range: dates });
            setPage("1");
          }}
          size="middle"
          presets={[
            {
              label: "This month",
              value: () => [moment().startOf("month"), moment().endOf("month")],
            },
            {
              label: "Last month",
              value: () => [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
            {
              label: "This week",
              value: () => [moment().startOf("week"), moment().endOf("week")],
            },
            {
              label: "Last week",
              value: () => [
                moment().subtract(1, "week").startOf("week"),
                moment().subtract(1, "week").endOf("week"),
              ],
            },
          ]}
          value={
            savedFilters?.date_range
              ? [
                  moment(savedFilters.date_range[0]),
                  moment(savedFilters.date_range[1]),
                ]
              : [null, null]
          }
        />
      </Col>

      <Col span={showTeamsFilter ? 12 : 24}>
        <Input
          allowClear
          size="middle"
          placeholder="Search for a meeting..."
          value={meetingName}
          onChange={(e) => {
            setPage("1");
            setMeetingName(e.target.value);
          }}
        />
      </Col>

      {showTeamsFilter && (
        <Col span={12}>
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            size="middle"
            placeholder="Teams"
            allowClear
            options={[
              ...teams.map((team) => {
                return {
                  label: team.name,
                  value: team.uuid,
                };
              }),
            ]}
            onChange={(team_uuids) => {
              setSavedFilters({
                ...savedFilters,
                team_uuids: team_uuids.length ? team_uuids : null,
              });
              setPage("1");
            }}
            value={savedFilters?.team_uuids}
          />
        </Col>
      )}
    </Row>
  );
}

export enum CRMFieldType {
  Id = "id",
  String = "string",
  EncryptedString = "encryptedstring",
  DateTime = "datetime",
  Date = "date",
  Time = "time",
  Currency = "currency",
  Double = "double",
  Percent = "percent",
  Boolean = "boolean",
  Email = "email",
  Phone = "phone",
  Picklist = "picklist",
  Multipicklist = "multipicklist",
  Url = "url",
  Textarea = "textarea",
  HTML = "html",
  Base64 = "base64",
  Reference = "reference",
}

export enum AutomatedCRMFieldType {
  String = CRMFieldType.String,
  EncryptedString = CRMFieldType.EncryptedString,
  Currency = CRMFieldType.Currency,
  Double = CRMFieldType.Double,
  Percent = CRMFieldType.Percent,
  Boolean = CRMFieldType.Boolean,
  Picklist = CRMFieldType.Picklist,
  Multipicklist = CRMFieldType.Multipicklist,
}

export enum CRMFieldTypeLabel {
  String = "String",
  EncryptedString = "Encrypted String",
  DateTime = "Datetime",
  Date = "Date",
  Time = "Time",
  Currency = "Currency",
  Double = "Number",
  Percent = "Percent",
  Boolean = "Boolean",
  Email = "Email",
  Phone = "Phone",
  Picklist = "Picklist",
  Multipicklist = "Multi Picklist",
  Url = "Url",
  Textarea = "Textarea",
  HTML = "Rich Textarea",
}

export enum GenerationModel {
  Gpt4o = "gpt-4o",
  Gpt4oMini = "gpt-4o-mini",
  Haiku = "haiku",
  Sonnet = "sonnet",
  Opus = "opus",
}

export enum Integration {
  Zoom = "zoom",
  ZoomAdmin = "zoom_admin",
  Slack = "slack",
  Gong = "gong",
  Avoma = "avoma",
  Microsoft = "microsoft",
  Salesloft = "salesloft",
  Salesforce = "salesforce",
  Hubspot = "hubspot",
  Chorus = "chorus",
}

export enum IntegrationLevel {
  User = "user",
  Organization = "organization",
}

export enum IntegrationType {
  CRM = "crm",
  Notification = "notification",
  WebConference = "web_conference",
  PhoneCall = "phone_call",
}

export enum WorkflowTrigger {
  MeetingSaved = "meeting_saved",
  MeetingAnalyzed = "meeting_analyzed",
  CRMRecordUpdated = "crm_record_updated",
}

export enum WorkflowVariableName {
  Query = "query",

  // Find, Update, Create CRM Record Variables
  CRM = "crm",
  CRMObjectType = "crm_object_type",
  CRMRecordId = "crm_record_id",
  CRMFieldValues = "crm_field_values",
  Value = "value",
  OverwriteExistingValue = "overwrite_existing_value",
  AssociatedCRMObjectType = "associated_crm_object_type",
  AssociatedCRMRecordId = "associated_crm_record_id",

  // Create Slack Channel Variables
  SlackChannelName = "slack_channel_name",
  SlackChannelIsPrivate = "slack_channel_is_private",

  // Send Slack Message Variables
  SlackMessageDestinationType = "slack_message_destination_type",
  SlackChannelId = "slack_channel_id",
  SlackMessageId = "slack_message_id",
  SlackUserIds = "slack_user_ids",
  SlackMessageBody = "slack_message_body",

  // Send Microsoft Teams Message Variables
  MicrosoftTeamsMessageDestinationType = "microsoft_teams_message_destination_type",
  MicrosoftTeamsTeamId = "microsoft_teams_team_id",
  MicrosoftTeamsChannelId = "microsoft_teams_channel_id",
  MicrosoftTeamsMessageId = "microsoft_teams_message_id",
  MicrosoftUserId = "microsoft_user_id",
  MicrosoftTeamsMessageBody = "microsoft_teams_message_body",
}

export enum WorkflowActionType {
  Trigger = "trigger",

  FindCRMRecord = "find_crm_record",
  UpdateCRMRecord = "update_crm_record",
  CreateCRMRecord = "create_crm_record",

  CreateSlackChannel = "create_slack_channel",
  SendSlackMessage = "send_slack_message",

  SendMicrosoftTeamsMessage = "send_microsoft_teams_message",
}

export enum WorkflowStatus {
  Triggered = "triggered",
  InProgress = "in_progress",
  Complete = "complete",
  Error = "error",
}

export enum ConditionalOperator {
  Exists = "exists",
  NotExists = "not_exists",
  Equals = "equals",
  NotEquals = "not_equals",
  Includes = "includes",
  NotIncludes = "not_includes",
  LessThan = "less_than",
  GreaterThan = "greater_than",
  LessThanEqualTo = "less_than_equal_to",
  GreaterThanEqualTo = "greater_than_equal_to",
}

export enum UserRole {
  SuperAdmin = "super_admin",
  User = "user",
  Collaborator = "collaborator",
  Admin = "admin",
  TechnicalAdmin = "technical_admin",
}

export enum FieldConfigurationAppendType {
  Top = "top",
  Bottom = "bottom",
  Overwrite = "overwrite",
  Smart = "smart",
  Merge = "merge",
}

export enum StandardFieldNames {
  Summary = "Summary__sw",
}

export enum MeetingType {
  WebConference = "web_conference",
  OutboundCall = "outbound_call",
  InboundCall = "inbound_call",
}

export enum MeetingStatusType {
  Analyzing = "analyzing",
  Unreviewed = "unreviewed",
  Viewed = "viewed",
  Synced = "synced",
  AutoSynced = "auto_synced",
}

export enum TeamDrawerTab {
  Edit = "edit",
  Users = "users",
  Layout = "layout",
}

export enum MessageDestinationType {
  ExistingChannel = "existing_channel",
  MessageThread = "message_thread",
  DirectMessage = "direct_message",
}

import React from "react";
import { Button, Flex, Input, Select } from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useFieldConfigurationsFilters } from "./useFieldConfigurationsFilters";
import { teamSelectTagRender } from "../../../shared/teamSelectTagRender";

interface Props {
  assignedTeamColors: Record<string, string>;
  createNewGroup: () => void;
}

export function FieldConfigurationsFilters({
  assignedTeamColors,
  createNewGroup,
}: Props) {
  const {
    filterValue,
    setFilterValue,
    teamFilterUuids,
    dispatchTeamFilterUuids,
    teamOptions,
  } = useFieldConfigurationsFilters();

  return (
    <Flex style={{ marginBottom: "0.5rem", width: "80%" }}>
      <Input
        style={{ width: "100%", marginRight: "0.5rem" }}
        allowClear
        value={filterValue}
        addonBefore={<SearchOutlined />}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder="Filter fields by name or label"
      />
      <Select
        tagRender={teamSelectTagRender(assignedTeamColors)}
        style={{ width: "60%", marginRight: "0.5rem" }}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        mode="multiple"
        size="middle"
        placeholder="Filter by team usage"
        allowClear
        options={teamOptions}
        onChange={(teamUuids: string[]) => {
          dispatchTeamFilterUuids(teamUuids);
        }}
        value={teamFilterUuids}
      />
      <Button
        icon={<PlusCircleOutlined />}
        onClick={() => createNewGroup()}
        type="primary"
      >
        Create New Group
      </Button>
    </Flex>
  );
}

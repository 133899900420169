import React from "react";
import { SelectProps, Tag } from "antd";

type TagRender = SelectProps["tagRender"];

export const teamSelectTagRender = (
  assignedTeamColors: Record<string, string>
): TagRender => {
  return (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={assignedTeamColors[value]}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };
};

import { Team } from "../util/types";
import _ from "lodash";

const colorOptions = [
  "green",
  "orange",
  "purple",
  "red",
  "blue",
  "volcano",
  "gold",
  "magenta",
  "lime",
  "cyan",
  "geekblue",
];

// Return a mapping of team UUIDs to colors
export const teamColors = (teams: Team[]): { [teamUuid: string]: string } => {
  return _.zipObject(
    teams.map((team) => team.uuid),
    teams.map((_, index) => colorOptions[index % colorOptions.length])
  );
};

import React from "react";
import { Alert, Button, message } from "antd";
import { FieldDefinition } from "../../../../../util/types";
import { CheckOutlined } from "@ant-design/icons";
import { revalidateFieldDefinition } from "../../../../../util/api";
import { get } from "lodash";

interface Props {
  fieldDefinition: FieldDefinition;
  refreshFieldDefinition: () => Promise<unknown>;
}

export function StaleAlert({ fieldDefinition, refreshFieldDefinition }: Props) {
  const [isRevalidating, setIsRevalidating] = React.useState<boolean>(false);
  const revalidate = async () => {
    setIsRevalidating(true);
    try {
      await revalidateFieldDefinition(fieldDefinition.uuid);
      await refreshFieldDefinition();
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(err, "response.status", 500) >= 500;

      message.error(
        is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
      );
    } finally {
      setIsRevalidating(false);
    }
  };

  return (
    fieldDefinition.stale && (
      <Alert
        message="Field recently changed in CRM. Please ensure that Swyft configurations are still correct."
        type="warning"
        style={{ marginBottom: "1rem" }}
        showIcon
        action={
          <Button
            size="small"
            icon={<CheckOutlined />}
            onClick={revalidate}
            loading={isRevalidating}
          >
            Done
          </Button>
        }
      />
    )
  );
}

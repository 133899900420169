import React from "react";
import {
  FieldConfigurationGroupLayout,
  FieldConfigurationLayout,
  Team,
} from "../../../util/types";
import { message, Space, Tooltip } from "antd";
import { SortableTable } from "../../shared/SortableTable";
import { CheckCircleTwoTone, EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { updateTeamFieldConfigurationLayout } from "../../../util/api";
import { useNavigate } from "react-router-dom";

interface Props {
  team: Team;
  group: FieldConfigurationGroupLayout;
  mutateLayout: () => void;
}

export function FieldConfigurationGroupLayoutTable({
  team,
  group,
  mutateLayout,
}: Props) {
  const navigate = useNavigate();
  const updatePosition = async ({ newOrder }) => {
    const fieldConfigurationLayouts = newOrder.map(({ uuid }, index) => {
      return { uuid, position: index + 1 };
    });

    try {
      await updateTeamFieldConfigurationLayout({
        teamUuid: team.uuid,
        layoutGroupUuid: group.uuid,
        fieldConfigurationLayouts,
      });
      mutateLayout();

      message.success("Group order updated");
    } catch (e) {
      message.error(`Error updating group order: ${e.response.data.message}`);
    }
  };
  const columns: ColumnsType<FieldConfigurationLayout> = [
    {
      title: "Status",
      dataIndex: "uuid",
      width: 5,
      align: "center",
      render: (_, layout: FieldConfigurationLayout) => {
        const icons = [
          <Tooltip key={0} title="Field visible on meeting form">
            <EyeOutlined />
          </Tooltip>,
        ];

        if (layout.field_configuration.automated) {
          icons.push(
            <Tooltip key={1} title="Field automated">
              <CheckCircleTwoTone />
            </Tooltip>
          );
        }

        return (
          <Space>
            {icons.map((icon, i) => (
              <div key={i}>{icon}</div>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Field Name",
      dataIndex: "uuid",
      render: (_, layout: FieldConfigurationLayout) => {
        return layout.field_configuration.label;
      },
    },
  ];
  return (
    <SortableTable<FieldConfigurationLayout>
      pagination={false}
      dataSource={group.field_configuration_layouts}
      filteredDataSource={group.field_configuration_layouts}
      columns={columns}
      onPositionUpdate={updatePosition}
      onRow={(layout: FieldConfigurationLayout) => ({
        onClick: () => {
          navigate(
            `/dashboard/settings/field-configurations/${layout.field_configuration.field_definition_uuid}?configuration=${layout.field_configuration.uuid}`
          );
        },
        style: { cursor: "pointer" },
      })}
    />
  );
}

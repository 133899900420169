import React from "react";
import { message } from "antd";
import { updateTeamFieldConfigurationGroupLayout } from "../../../util/api";
import { SortableTable } from "../../shared/SortableTable";
import { FieldConfigurationGroupLayout, Team } from "../../../util/types";
import { FieldConfigurationGroupLayoutTable } from "./FieldConfigurationGroupLayoutTable";

interface Props {
  team: Team;
  fieldConfigurationGroupLayouts: FieldConfigurationGroupLayout[];
  mutateLayout: () => void;
}

export function FieldConfigurationGroupsLayoutTable({
  team,
  fieldConfigurationGroupLayouts,
  mutateLayout,
}: Props) {
  const updateOrder = async ({ newOrder }) => {
    const fieldConfigurationGroupLayouts = newOrder.map(({ uuid }, index) => {
      return { uuid, position: index + 1 };
    });

    try {
      await updateTeamFieldConfigurationGroupLayout({
        teamUuid: team.uuid,
        fieldConfigurationGroupLayouts,
      });
      mutateLayout();

      message.success("Group order updated");
    } catch (e) {
      message.error(`Error updating group order: ${e.response.data.message}`);
    }
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "uuid",
      render: (uuid) => {
        const group = fieldConfigurationGroupLayouts.find(
          (group) => group.uuid === uuid
        );
        return group.field_configuration_group.label;
      },
    },
  ];
  return (
    <SortableTable<FieldConfigurationGroupLayout>
      pagination={false}
      dataSource={fieldConfigurationGroupLayouts}
      filteredDataSource={fieldConfigurationGroupLayouts}
      columns={columns}
      onPositionUpdate={updateOrder}
      expandable={{
        expandedRowRender: (group: FieldConfigurationGroupLayout) => (
          <FieldConfigurationGroupLayoutTable
            team={team}
            group={group}
            mutateLayout={mutateLayout}
          />
        ),
      }}
    />
  );
}

import { GenerationModel } from "./enums";

export const GenerationModelDropdownOptions = [
  {
    label: "GPT-4o-mini",
    value: GenerationModel.Gpt4oMini,
  },
  {
    label: "GPT-4o",
    value: GenerationModel.Gpt4o,
  },
  {
    label: "Haiku",
    value: GenerationModel.Haiku,
  },
  {
    label: "Sonnet",
    value: GenerationModel.Sonnet,
  },
  {
    label: "Opus",
    value: GenerationModel.Opus,
  },
];

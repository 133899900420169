import { Button, Card, Modal, Space, Typography } from "antd";
import React from "react";
import { Connection, Handle, Position } from "reactflow";
import { WorkflowState, WorkflowTransition } from "../../../util/types";
import { useWorkflow } from "../../../util/data_hooks";
import { gold } from "@ant-design/colors";
import { fetchUpdateWorkflowTransition } from "../../../util/api";
import { useNavigate, useParams } from "react-router-dom";

interface WorkflowTransitionNodeProps {
  data: {
    workflowState: WorkflowState;
    workflowTransition: WorkflowTransition;
  };
}

export function WorkflowTransitionNode(props: WorkflowTransitionNodeProps) {
  const {
    data: { workflowState, workflowTransition },
  } = props;

  const navigate = useNavigate();
  const { workflowUuid } = useParams();
  const { mutate: mutateWorkflow } = useWorkflow(workflowUuid);

  const onConnectConfirm = async ({
    isTarget,
    workflowStateUuid,
  }: {
    isTarget: boolean;
    workflowStateUuid: string;
  }) => {
    const body: Partial<WorkflowTransition> = isTarget
      ? { target_workflow_state_uuid: workflowStateUuid }
      : { fallback_target_workflow_state_uuid: workflowStateUuid };

    await fetchUpdateWorkflowTransition(workflowTransition.uuid, body);

    mutateWorkflow();

    Modal.destroyAll();
  };

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={false} />

      <Card
        title="Condition"
        size="small"
        styles={{
          header: { backgroundColor: gold[2] },
        }}
        style={{ width: 300, textAlign: "center" }}
        actions={[
          ...(workflowTransition.target_workflow_state_name
            ? []
            : [
                <Typography.Link
                  key="true"
                  onClick={() => {
                    const params = new URLSearchParams({
                      workflowTransitionUuid: workflowTransition.uuid,
                    });

                    navigate(
                      `workflow-states/${workflowState.uuid}/new?${params}`
                    );
                  }}
                >
                  Add True Action
                </Typography.Link>,
              ]),

          ...(workflowTransition.fallback_target_workflow_state_uuid
            ? []
            : [
                <Typography.Link
                  disabled={
                    !!workflowTransition.fallback_target_workflow_state_uuid
                  }
                  key="false"
                  onClick={() => {
                    const params = new URLSearchParams({
                      workflowTransitionUuid: workflowTransition.uuid,
                      isFallback: "true",
                    });

                    navigate(
                      `workflow-states/${workflowState.uuid}/new?${params}`
                    );
                  }}
                >
                  Add False Action
                </Typography.Link>,
              ]),
        ]}
      >
        <Typography.Link
          onClick={() =>
            navigate(
              `workflow-states/${workflowState.uuid}/workflow-transitions/${workflowTransition.uuid}`
            )
          }
        >
          {workflowTransition.name}
        </Typography.Link>
      </Card>

      <Handle
        type="source"
        position={Position.Bottom}
        onConnect={(connection: Connection) =>
          Modal.info({
            title: "Run when Condition is True or False?",
            footer: (
              <Space style={{ margin: "15px 0px" }}>
                <Button
                  onClick={() =>
                    onConnectConfirm({
                      isTarget: true,
                      workflowStateUuid: connection.target,
                    })
                  }
                >
                  Run when True
                </Button>

                <Button
                  onClick={() =>
                    onConnectConfirm({
                      isTarget: false,
                      workflowStateUuid: connection.target,
                    })
                  }
                >
                  Run when False
                </Button>
              </Space>
            ),
          })
        }
      />
    </>
  );
}

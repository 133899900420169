import React, { useState } from "react";
import {
  DEFAULT_FILTERS,
  MeetingTableFilters,
  MeetingsTable,
  MeetingsTableParam,
  getFiltersCount,
} from "../shared/MeetingsTable";
import { useOrganizationMeetings, useUser } from "../../util/data_hooks";
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import {
  UserOutlined,
  FilterTwoTone,
  CalendarOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import {
  getFriendlyMeetingStatusLabel,
  getFriendlyMeetingTypeLabel,
} from "../../helpers/label_maps";
import { usePageTitle } from "../hooks/usePageTitle";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import { MeetingFilters } from "../shared/MeetingFilters";

export const TEAM_MEETINGS_TABLE_FILTERS = "TEAM_MEETINGS_TABLE_FILTERS";

function StatCard({
  stat,
  title,
  tooltip,
}: {
  stat: number;
  title: string;
  tooltip: string;
}) {
  return (
    <Card
      size="small"
      title={
        <Space size="small">
          <Typography.Text>{title}</Typography.Text>

          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <Typography.Title style={{ marginTop: 0, marginBottom: 10 }}>
        {stat?.toLocaleString()}
      </Typography.Title>
    </Card>
  );
}

export function TeamMeetings() {
  usePageTitle("Team Meetings");

  const navigate = useNavigate();
  const { search } = useLocation();

  const [savedFilters, setSavedFilters] = useLocalStorage<MeetingTableFilters>(
    TEAM_MEETINGS_TABLE_FILTERS,
    DEFAULT_FILTERS
  );

  const filtersCount = getFiltersCount(savedFilters);

  const [currentPage, setPage] = useSearchParamsState(
    MeetingsTableParam.Page,
    "1"
  );
  const [pageSize] = useSearchParamsState(MeetingsTableParam.PageSize, "10");

  const [meetingName, setMeetingName] = useState(null);
  const debouncedMeetingName = useDebounce(meetingName, 600);

  const {
    organizationMeetings,
    meta,
    analytics,
    isLoading: isMeetingsLoading,
  } = useOrganizationMeetings({
    page: parseInt(currentPage) - 1,
    pageSize: parseInt(pageSize),
    meetingName: debouncedMeetingName,
  });

  const { user, isLoading: isUserLoading } = useUser();

  const isLoading = isUserLoading || isMeetingsLoading;

  return (
    <>
      <Outlet />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <Row justify="space-between" align="middle" gutter={[12, 12]}>
          <Col span={12}>
            <Typography.Title level={3}>
              Team Meetings for {user?.organization?.name}
            </Typography.Title>

            <Typography.Text>
              View meeting activity and details across your entire revenue team
            </Typography.Text>
          </Col>

          <Col span={12}>
            <MeetingFilters
              savedFilters={savedFilters}
              meetingName={meetingName}
              setSavedFilters={setSavedFilters}
              setMeetingName={setMeetingName}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {filtersCount > 0 && (
            <Col span={24}>
              <Card
                title={
                  <Space>
                    <FilterTwoTone />

                    <Typography.Text>
                      {filtersCount} {filtersCount === 1 ? "filter" : "filters"}{" "}
                      applied
                    </Typography.Text>
                  </Space>
                }
                size="small"
              >
                <Row align="middle" justify="space-between" gutter={[12, 12]}>
                  <Col>
                    <Row gutter={[12, 12]}>
                      {savedFilters.date_range && (
                        <Col>
                          <Tag icon={<CalendarOutlined />}>
                            Date range:{" "}
                            <b>
                              {moment(savedFilters.date_range[0]).format(
                                "MMMM Do YYYY"
                              )}
                            </b>{" "}
                            to{" "}
                            <b>
                              {moment(savedFilters.date_range[1]).format(
                                "MMMM Do YYYY"
                              )}
                            </b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.meeting_types && (
                        <Col>
                          <Tag icon={<UnorderedListOutlined />}>
                            Call Types:{" "}
                            <b>
                              {savedFilters.meeting_types
                                .map((type) =>
                                  getFriendlyMeetingTypeLabel(type)
                                )
                                .join(", ")}
                            </b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.users && (
                        <Col>
                          <Tag icon={<UserOutlined />}>
                            Users: <b>{savedFilters.users.length}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.team_uuids && (
                        <Col>
                          <Tag icon={<TeamOutlined />}>
                            Teams: <b>{savedFilters.team_uuids.length}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.crm_record_ids && (
                        <Col>
                          <Tag icon={<FileTextOutlined />}>
                            CRM Records:{" "}
                            <b>{savedFilters.crm_record_ids.length}</b>
                          </Tag>
                        </Col>
                      )}

                      {savedFilters.meeting_status && (
                        <Col>
                          <Tag icon={<InfoCircleOutlined />}>
                            Status:{" "}
                            <b>
                              {savedFilters.meeting_status
                                .map((status) =>
                                  getFriendlyMeetingStatusLabel(status)
                                )
                                .join(", ")}
                            </b>
                          </Tag>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      icon={<CloseCircleFilled />}
                      onClick={() => {
                        setPage("1");
                        setSavedFilters(DEFAULT_FILTERS);
                        setMeetingName(null);
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          <Col span={8}>
            <StatCard
              title="Meetings Analyzed"
              tooltip="Number of meetings analyzed by Swyft"
              stat={analytics?.meetings_analyzed || 0}
            />
          </Col>

          <Col span={8}>
            <StatCard
              title="Field Data Generated"
              tooltip="Number of fields where Swyft was able to generate data for analyzed meetings"
              stat={analytics?.fields_generated || 0}
            />
          </Col>

          <Col span={8}>
            <StatCard
              title="Synced CRM Records"
              tooltip="Number of synced CRM records for analyzed meetings"
              stat={analytics?.synced_crm_records || 0}
            />
          </Col>
        </Row>

        <MeetingsTable
          includeUserColumn
          meetings={organizationMeetings}
          totalMeetings={meta.total}
          user={user}
          savedFiltersKey={TEAM_MEETINGS_TABLE_FILTERS}
          isLoading={isLoading}
          onRowClick={(meetingUuid) => navigate(`${meetingUuid}${search}`)}
          empty={
            <div style={{ margin: "100px 0px" }}>
              {isLoading ? (
                <Typography.Text>Loading...</Typography.Text>
              ) : (
                <Empty
                  description={
                    <Typography.Title level={5}>
                      No meetings or calls found
                    </Typography.Title>
                  }
                />
              )}
            </div>
          }
        />
      </Space>
    </>
  );
}

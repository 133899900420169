import React, { useEffect, useState } from "react";
import { Button, Divider, Empty, Form, Radio, Select, Skeleton } from "antd";
import {
  useIntegrationUsers,
  useMicrosoftChannels,
  useMicrosoftTeams,
  useUser,
} from "../../../../util/data_hooks";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import {
  Integration,
  MessageDestinationType,
  WorkflowVariableName,
} from "../../../../util/enums";
import { useIntegrationWindow } from "../../../hooks/useIntegrationWindow";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";
import { DynamicVariableTextarea } from "./shared/dynamic_variables/DynamicVariableTextarea";

export function SendMicrosoftTeamsMessage() {
  const form = Form.useFormInstance();
  const [openIntegrationWindow] = useIntegrationWindow();

  const [destinationType, setDestinationType] =
    useState<MessageDestinationType>(null);
  const [teamId, setTeamId] = useState<string>(null);

  const { user, isLoading: isUserLoading } = useUser();
  const {
    response: { integration_users },
    isLoading: isUsersLoading,
  } = useIntegrationUsers(Integration.Microsoft);
  const { teams, isLoading: isTeamsLoading } = useMicrosoftTeams();
  const { channels, isLoading: isChannelsLoading } =
    useMicrosoftChannels(teamId);

  useEffect(() => {
    const dynamicNames = {
      [MessageDestinationType.ExistingChannel]: "Send Teams Message to Channel",
      [MessageDestinationType.DirectMessage]: "Send Teams DM",
      [MessageDestinationType.MessageThread]: "Send Teams Message to Thread",
    };

    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      dynamicNames[destinationType]
    );
  }, [destinationType]);

  Form.useWatch((formValues) => {
    const destinationType =
      formValues?.variables?.microsoft_teams_message_destination_type;
    const teamId = formValues?.variables?.microsoft_teams_team_id;

    if (destinationType) setDestinationType(destinationType);
    if (teamId) setTeamId(teamId);
  }, form);

  const isLoading =
    isUsersLoading || isUserLoading || isTeamsLoading || isChannelsLoading;

  if (isLoading) return <Skeleton active />;

  if (!user.organization.microsoft_oauth_user) {
    return (
      <Empty description="Microsoft Teams is not connected for your organization">
        <Button
          type="primary"
          onClick={() => openIntegrationWindow(Integration.Microsoft)}
        >
          Connect Microsoft Teams
        </Button>
      </Empty>
    );
  }

  return (
    <>
      <Form.Item
        rules={[{ required: true }]}
        label="Message Destination"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.MicrosoftTeamsMessageDestinationType,
        ]}
      >
        <Radio.Group
          optionType="button"
          options={[
            {
              label: "Existing Channel",
              value: MessageDestinationType.ExistingChannel,
            },
            {
              label: "Direct Message",
              value: MessageDestinationType.DirectMessage,
            },
            {
              label: "Thread",
              value: MessageDestinationType.MessageThread,
            },
          ]}
        />
      </Form.Item>

      {destinationType === MessageDestinationType.DirectMessage && (
        <Form.Item
          label="User"
          name={[
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.MicrosoftUserId,
          ]}
          rules={[{ required: true }]}
        >
          <DynamicVariableSelect
            staticOptions={integration_users.map(({ id, name, email }) => {
              return {
                label: `${name} (${email})`,
                value: id,
              };
            })}
            value={form.getFieldValue([
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.MicrosoftUserId,
            ])}
            onChange={(value) => {
              form.setFieldValue(
                [
                  WorkflowStateEditorFieldName.Variables,
                  WorkflowVariableName.MicrosoftUserId,
                ],
                value
              );
            }}
          />
        </Form.Item>
      )}

      {destinationType === MessageDestinationType.ExistingChannel && (
        <>
          <Form.Item
            label="Team"
            name={[
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.MicrosoftTeamsTeamId,
            ]}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a team"
              showSearch
              optionFilterProp="label"
              options={teams.map(({ id, name }) => {
                return { label: name, value: id };
              })}
              value={form.getFieldValue([
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.MicrosoftTeamsTeamId,
              ])}
              onChange={(value) => {
                form.setFieldValue(
                  [
                    WorkflowStateEditorFieldName.Variables,
                    WorkflowVariableName.MicrosoftTeamsTeamId,
                  ],
                  value
                );
              }}
            />
          </Form.Item>

          {teamId && (
            <Form.Item
              label="Channel"
              name={[
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.MicrosoftTeamsChannelId,
              ]}
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a channel"
                showSearch
                optionFilterProp="label"
                options={channels.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                value={form.getFieldValue([
                  WorkflowStateEditorFieldName.Variables,
                  WorkflowVariableName.MicrosoftTeamsChannelId,
                ])}
                onChange={(value) => {
                  form.setFieldValue(
                    [
                      WorkflowStateEditorFieldName.Variables,
                      WorkflowVariableName.MicrosoftTeamsChannelId,
                    ],
                    value
                  );
                }}
              />
            </Form.Item>
          )}
        </>
      )}

      {destinationType === MessageDestinationType.MessageThread && (
        <Form.Item
          label="Message Id"
          name={[
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.MicrosoftTeamsMessageId,
          ]}
          rules={[{ required: true }]}
        >
          <DynamicVariableSelect
            value={form.getFieldValue([
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.MicrosoftTeamsMessageId,
            ])}
            onChange={(value) => {
              form.setFieldValue(
                [
                  WorkflowStateEditorFieldName.Variables,
                  WorkflowVariableName.MicrosoftTeamsMessageId,
                ],
                value
              );
            }}
          />
        </Form.Item>
      )}

      <Divider />

      <Form.Item
        rules={[{ required: true }]}
        label="Message Body"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.MicrosoftTeamsMessageBody,
        ]}
      >
        <DynamicVariableTextarea
          value={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.MicrosoftTeamsMessageBody,
          ])}
          onChange={(value) => {
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.MicrosoftTeamsMessageBody,
              ],
              value
            );
          }}
        />
      </Form.Item>
    </>
  );
}

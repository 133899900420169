import React, { useState } from "react";
import { Table, Tag, Typography, Row, Col, Tooltip, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MailOutlined, EditOutlined } from "@ant-design/icons";
import { IntegrationInfo, Organization, User } from "../../../util/types";
import { UserEditorModal } from "../shared/UserEditorModal";
import { getRelativeTime } from "../../../helpers/date_helpers";
import { UserRole } from "../../../util/enums";
import {
  useOrganizationUsers,
  useOrganizationUsersIntegrations,
} from "../../../util/data_hooks";
import { IntegrationLogo } from "../../shared/IntegrationLogo";

interface OrganizationUsersProps {
  organization: Organization;
  users: User[];
  usersLoading: boolean;
  mutateOrganization: () => void;
}

interface UserWithIntegrations extends User {
  crmIntegration: IntegrationInfo;
  meetingIntegrations: IntegrationInfo[];
  notificationIntegrations: IntegrationInfo[];
}

const mergeUserIntegrations = (
  users: User[],
  userIntegrations: {
    uuid: string;
    meeting_integrations: IntegrationInfo[];
    crm_integration: IntegrationInfo;
    notification_integrations: IntegrationInfo[];
  }[]
): UserWithIntegrations[] => {
  return users.map((user) => {
    const integration = userIntegrations.find(
      (integration) => integration.uuid === user.uuid
    );
    return {
      ...user,
      meetingIntegrations: integration?.meeting_integrations || [],
      crmIntegration: integration?.crm_integration,
      notificationIntegrations: integration?.notification_integrations || [],
    };
  });
};

export function OrganizationUsers({
  organization,
  users,
  usersLoading,
  mutateOrganization,
}: OrganizationUsersProps) {
  const [editableUser, setEditableUser] = useState<User>(null);
  const [isUserEditorModalOpen, setIsUserEditorModalOpen] =
    useState<boolean>(false);
  const { userIntegrations, isLoading: isUserIntegrationsLoading } =
    useOrganizationUsersIntegrations();

  const { mutate: mutateOrganizationUsers } = useOrganizationUsers();

  const mergedUserIntegrations = mergeUserIntegrations(
    users,
    userIntegrations
  ).sort((a, b) => {
    return (
      Number(b.active) - Number(a.active) ||
      Number(b.roles.includes(UserRole.User)) -
        Number(a.roles.includes(UserRole.User))
    );
  });

  const columns: ColumnsType<UserWithIntegrations> = [
    {
      title: "Email",
      dataIndex: "email",
      render: (email, user) => (
        <Row gutter={8} align="middle">
          {user.active && user.pending_invitations.length > 0 && (
            <Tooltip
              title={`Invited ${getRelativeTime(
                user.pending_invitations[0].last_sent_at
              )} by ${user.pending_invitations[0].inviter_email}`}
            >
              <Col>
                <MailOutlined />
              </Col>
            </Tooltip>
          )}

          <Col>
            <Typography.Link
              onClick={() => {
                setEditableUser(user);
                setIsUserEditorModalOpen(true);
              }}
            >
              {email} <EditOutlined />
            </Typography.Link>
          </Col>
        </Row>
      ),
    },
    {
      title: `Permission Profile`,
      dataIndex: "roles",
      render: (roles, { active }) => {
        const roleOrder = [
          UserRole.User,
          UserRole.Collaborator,
          UserRole.Admin,
          UserRole.TechnicalAdmin,
        ];

        const orderedRoles = roles.sort(
          (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
        );

        if (!active) return <Tag>Inactive</Tag>;

        return orderedRoles.map((role) => {
          switch (role) {
            case UserRole.User:
              return (
                <Tooltip
                  key={role}
                  title="Standard users have their meetings analyzed. Requires a paid seat"
                >
                  <Tag color="green">Standard User</Tag>
                </Tooltip>
              );
            case UserRole.Collaborator:
              return (
                <Tooltip
                  key={role}
                  title="Collaborators are granted read-only access to meetings"
                >
                  <Tag color="blue">Collaborator</Tag>
                </Tooltip>
              );
            case UserRole.Admin:
              return (
                <Tooltip
                  key={role}
                  title="Admins can manage and invite team members"
                >
                  <Tag color="volcano">Admin</Tag>
                </Tooltip>
              );
            case UserRole.TechnicalAdmin:
              return (
                <Tooltip
                  key={role}
                  title="Technical Admins can manage field configurations & workflows"
                >
                  <Tag color="purple">Technical Admin</Tag>
                </Tooltip>
              );
          }
        });
      },
    },
    {
      title: `Connected Accounts`,
      render: (
        _,
        { crmIntegration, meetingIntegrations, notificationIntegrations }
      ) => {
        const connectedIntegrations = [
          crmIntegration,
          ...meetingIntegrations,
          ...notificationIntegrations,
        ]
          .filter(Boolean)
          .filter(({ connected }) => connected);

        return connectedIntegrations.length > 0 ? (
          <Space size="small">
            {connectedIntegrations.map(({ name }) => (
              <IntegrationLogo key={name} type={name} width={20} />
            ))}
          </Space>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <>
      <UserEditorModal
        user={editableUser}
        organization={organization}
        open={isUserEditorModalOpen}
        setOpen={setIsUserEditorModalOpen}
        afterSave={() => {
          mutateOrganization();
          mutateOrganizationUsers();
        }}
      />

      <Table<UserWithIntegrations>
        rowKey={({ uuid }) => uuid}
        loading={!organization || usersLoading || isUserIntegrationsLoading}
        dataSource={mergedUserIntegrations}
        columns={columns}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { useCRMObjectFields } from "../../../../util/data_hooks";
import { CRMObject, CRMProvider, WorkflowState } from "../../../../util/types";
import { CRMFieldValuesFormItem } from "./shared/CRMFieldValuesFormItem";
import { CRMFieldType, WorkflowVariableName } from "../../../../util/enums";
import { CRMSelectFormItem } from "./shared/CRMSelectFormItem";
import { CRMObjectSelectFormItem } from "./shared/CRMObjectSelectFormItem";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";

export function CreateCRMRecord({
  workflowState,
}: {
  workflowState: WorkflowState;
}) {
  const form = Form.useFormInstance();

  const [selectedCRM, setSelectedCRM] = useState<CRMProvider>(null);
  const [selectedCRMObject, setSelectedCRMObject] = useState<CRMObject>(null);
  const [selectedAssociatedCRMObject, setSelectedAssociatedCRMObject] =
    useState<CRMObject>(null);

  const { objectFields, isLoading: isCRMObjectFieldsLoading } =
    useCRMObjectFields(selectedCRM, selectedCRMObject?.crm_object_name, true);

  useEffect(() => {
    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      `Create ${getFriendlyIntegrationLabel(selectedCRM) || "CRM"} ${
        selectedCRMObject?.crm_object_label || "Record"
      }`
    );
  }, [selectedCRM, selectedCRMObject]);

  if (isCRMObjectFieldsLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Select Object Type</Typography.Title>
        <Typography.Text type="secondary">
          What type of record should be created?
        </Typography.Text>
      </div>

      <CRMSelectFormItem onChange={setSelectedCRM} />

      <CRMObjectSelectFormItem
        crm={selectedCRM}
        onChange={setSelectedCRMObject}
      />

      <Divider />

      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Associate Parent Record</Typography.Title>
        <Typography.Text type="secondary">
          This is the record in the CRM that your new record will be associated
          with.
        </Typography.Text>
      </div>

      <Row gutter={12}>
        <Col span={12}>
          <CRMObjectSelectFormItem
            crm={selectedCRM}
            onChange={setSelectedAssociatedCRMObject}
            name={[
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.AssociatedCRMObjectType,
            ]}
            label="Parent object type"
            required={false}
          />
        </Col>

        <Col span={12}>
          <Form.Item
            label="Parent record Id"
            name={[
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.AssociatedCRMRecordId,
            ]}
          >
            <DynamicVariableSelect
              crmObjectType={selectedAssociatedCRMObject?.crm_object_name}
              fieldType={CRMFieldType.Id}
              value={form.getFieldValue([
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.AssociatedCRMRecordId,
              ])}
              onChange={(value) =>
                form.setFieldValue(
                  [
                    WorkflowStateEditorFieldName.Variables,
                    WorkflowVariableName.AssociatedCRMRecordId,
                  ],
                  value
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {selectedCRMObject && (
        <>
          <Divider />

          <div style={{ marginBottom: 15 }}>
            <Typography.Title level={4}>Field Values</Typography.Title>
            <Typography.Text type="secondary">
              Add fields to be populated on the new record.
            </Typography.Text>
          </div>

          <CRMFieldValuesFormItem
            workflowState={workflowState}
            objectFields={objectFields}
            showRequiredFields
          />
        </>
      )}
    </>
  );
}

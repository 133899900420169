import { Form } from "antd";
import { useSlackUsers } from "../../../../../util/data_hooks";
import React from "react";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowVariableName } from "../../../../../util/enums";
import { DynamicVariableSelect } from "./dynamic_variables/DynamicVariableSelect";
import SkeletonInput from "antd/es/skeleton/Input";

export function SlackUsersSelectFormItem({
  label,
  required = false,
}: {
  label: string;
  required?: boolean;
}) {
  const form = Form.useFormInstance();

  const { slackUsers, isLoading } = useSlackUsers();

  return (
    <Form.Item
      label={label}
      name={[
        WorkflowStateEditorFieldName.Variables,
        WorkflowVariableName.SlackUserIds,
      ]}
      rules={[{ required }]}
    >
      {isLoading ? (
        <SkeletonInput active style={{ width: 500 }} />
      ) : (
        <DynamicVariableSelect
          isMultiSelect
          staticOptions={slackUsers.map(({ user_id, username, real_name }) => {
            return {
              label: `${real_name} (${username})`,
              value: user_id,
            };
          })}
          value={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.SlackUserIds,
          ])}
          onChange={(value) => {
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.SlackUserIds,
              ],
              value
            );
          }}
        />
      )}
    </Form.Item>
  );
}

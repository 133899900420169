import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { useCRMObjectFields } from "../../../../util/data_hooks";
import { CRMObject, CRMProvider, WorkflowState } from "../../../../util/types";
import { CRMObjectSelectFormItem } from "./shared/CRMObjectSelectFormItem";
import { CRMSelectFormItem } from "./shared/CRMSelectFormItem";
import { CRMFieldType, WorkflowVariableName } from "../../../../util/enums";
import { CRMFieldValuesFormItem } from "./shared/CRMFieldValuesFormItem";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";

interface UpdateCRMRecordProps {
  workflowState: WorkflowState;
}

export function UpdateCRMRecord({ workflowState }: UpdateCRMRecordProps) {
  const form = Form.useFormInstance();

  const [selectedCRM, setSelectedCRM] = useState<CRMProvider>(null);
  const [selectedCRMObject, setSelectedCRMObject] = useState<CRMObject>(null);

  const { objectFields, isLoading: isCRMObjectFieldsLoading } =
    useCRMObjectFields(selectedCRM, selectedCRMObject?.crm_object_name, true);

  useEffect(() => {
    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      `Update ${getFriendlyIntegrationLabel(selectedCRM) || "CRM"} ${
        selectedCRMObject?.crm_object_label || "Record"
      }`
    );
  }, [selectedCRM, selectedCRMObject]);

  if (isCRMObjectFieldsLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Select Object Type</Typography.Title>

        <Typography.Text type="secondary">
          This is used to find the record in{" "}
          {getFriendlyIntegrationLabel(selectedCRM)} to be updated.
        </Typography.Text>
      </div>

      <CRMSelectFormItem onChange={setSelectedCRM} />

      <Row gutter={12}>
        <Col span={12}>
          <CRMObjectSelectFormItem
            crm={selectedCRM}
            onChange={setSelectedCRMObject}
          />
        </Col>

        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            label="Record Id"
            name={[
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.CRMRecordId,
            ]}
          >
            <DynamicVariableSelect
              fieldType={CRMFieldType.Id}
              crmObjectType={selectedCRMObject?.crm_object_name}
              value={form.getFieldValue([
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.CRMRecordId,
              ])}
              onChange={(value) =>
                form.setFieldValue(
                  [
                    WorkflowStateEditorFieldName.Variables,
                    WorkflowVariableName.CRMRecordId,
                  ],
                  value
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {selectedCRMObject && (
        <>
          <Divider />

          <div style={{ marginBottom: 15 }}>
            <Typography.Title level={4}>Field Values</Typography.Title>
            <Typography.Text type="secondary">
              Which fields should be updated?
            </Typography.Text>
          </div>

          <CRMFieldValuesFormItem
            workflowState={workflowState}
            objectFields={objectFields}
          />
        </>
      )}
    </>
  );
}

import { Edge, Node } from "reactflow";
import { Workflow } from "../../../../util/types";

export const DEFAULT_EDGE_TYPE = "smoothstep";
export const REMOVEABLE_EDGE = "removeableedge";

enum WorkflowNodeType {
  Trigger = "workflow_trigger",
  State = "workflow_state",
  Transition = "workflow_transition",
}

export const getInitialWorkflowElements = (
  workflow: Workflow
): { initialNodes: Node[]; initialEdges: Edge[] } => {
  const initialNodes: Node[] = [];
  const initialEdges: Edge[] = [];

  workflow.workflow_states.forEach((workflowState) => {
    initialNodes.push({
      id: workflowState.uuid,
      position: null,
      data: { workflowState },
      connectable: false,
      type: WorkflowNodeType.State,
    });

    workflowState.workflow_transitions.forEach((workflowTransition) => {
      const {
        uuid,
        workflow_state_uuid,
        target_workflow_state_uuid,
        fallback_target_workflow_state_uuid,
        conditions,
      } = workflowTransition;

      if (conditions) {
        initialEdges.push({
          id: uuid,
          source: workflow_state_uuid,
          target: uuid,
          type: DEFAULT_EDGE_TYPE,
        });

        initialNodes.push({
          id: uuid,
          position: null,
          data: { workflowState, workflowTransition },
          connectable: false,
          type: WorkflowNodeType.Transition,
        });

        if (target_workflow_state_uuid) {
          initialEdges.push({
            id: `${uuid}-target`,
            source: uuid,
            target: target_workflow_state_uuid,
            label: "Is True",
            animated: true,
            type: REMOVEABLE_EDGE,
            data: {
              workflowUuid: workflow.uuid,
              isConditionalEdge: true,
              isTargetConditionalEdge: true,
            },
          });
        }

        if (fallback_target_workflow_state_uuid) {
          initialEdges.push({
            id: `${uuid}-fallback-target`,
            source: uuid,
            target: fallback_target_workflow_state_uuid,
            label: "Is False",
            animated: true,
            type: REMOVEABLE_EDGE,
            data: {
              workflowUuid: workflow.uuid,
              isConditionalEdge: true,
              isTargetConditionalEdge: false,
            },
          });
        }
      } else {
        initialEdges.push({
          id: workflowTransition.uuid,
          source: workflowTransition.workflow_state_uuid,
          target: workflowTransition.target_workflow_state_uuid,
          type: REMOVEABLE_EDGE,
          data: { workflowUuid: workflow.uuid },
        });
      }
    });
  });

  return { initialNodes, initialEdges };
};

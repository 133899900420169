import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FieldConfiguration, FieldDefinition } from "../../../../util/types";

export interface PlaygroundSlice {
  fieldToTest: FieldConfiguration;
  fieldDefinition: FieldDefinition;
  meetingNameFilter: string;
  meetingsSelectedTeamFilter: string[]; // Team UUIDS
  meetingsPage: number;
  meetingsPageSize: number;
  currentFieldConfigurationTab: string;
}

const initialState: PlaygroundSlice = {
  fieldToTest: null,
  fieldDefinition: null,
  meetingNameFilter: "",
  meetingsSelectedTeamFilter: [],
  meetingsPage: 0,
  meetingsPageSize: 10,
  currentFieldConfigurationTab: "",
};

export const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {
    setFieldToTest: (
      state,
      action: PayloadAction<{
        fieldConfiguration: FieldConfiguration;
        fieldDefinition: FieldDefinition;
      }>
    ) => {
      state.fieldDefinition = action.payload.fieldDefinition;
      state.fieldToTest = action.payload.fieldConfiguration;
    },
    setMeetingsSelectedTeamFilter: (state, action: PayloadAction<string[]>) => {
      state.meetingsSelectedTeamFilter = action.payload;
    },
    setMeetingNameFilter: (state, action: PayloadAction<string>) => {
      state.meetingNameFilter = action.payload;
    },
    setMeetingsPage: (state, action: PayloadAction<number>) => {
      state.meetingsPage = action.payload;
    },
    setMeetingsPageSize: (state, action: PayloadAction<number>) => {
      state.meetingsPageSize = action.payload;
    },
    setCurrentFieldConfigurationTab: (state, action: PayloadAction<string>) => {
      state.currentFieldConfigurationTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFieldToTest,
  setMeetingNameFilter,
  setMeetingsPageSize,
  setMeetingsPage,
  setMeetingsSelectedTeamFilter,
  setCurrentFieldConfigurationTab,
} = playgroundSlice.actions;

export default playgroundSlice.reducer;

import { Button, Result, Space } from "antd";
import React from "react";

export function Page404() {
  return (
    <Result
      status={"404"}
      title={"Something's missing."}
      subTitle={
        "Sorry, we can't find that page. You'll find lots to explore on the home page."
      }
      extra={
        <Space>
          <Button type="primary" href={"/dashboard/meetings"}>
            Back Home
          </Button>
        </Space>
      }
    />
  );
}

import React, { useEffect, useState } from "react";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import { useDebounce } from "@uidotdev/usehooks";
import {
  AssociatedCRMRecord,
  CRMRecord,
  LayoutedFieldConfigurationGroup,
  Meeting,
  SyncedCRMObject,
} from "../../util/types";
import {
  createAssociatedCRMRecord,
  createIgnoredSyncedCRMObject,
  deleteIgnoredSyncedCRMObject,
  fetchBulkUpdateAutoGeneratedFields,
  getCRMRecordsByName,
} from "../../util/api";
import { get } from "lodash";

function CRMRecordSearch({
  syncedCRMObject,
  associatedCRMRecord,
  crmRecord,
  isIgnored,
  onIgnore,
  onUnignore,
  onSelect,
}: {
  syncedCRMObject: SyncedCRMObject;
  associatedCRMRecord?: AssociatedCRMRecord;
  crmRecord?: CRMRecord;
  isIgnored: boolean;
  onIgnore: () => void;
  onUnignore: () => void;
  onSelect: ({ crmRecordId, crmRecordName }) => void;
}) {
  const [query, setQuery] = useState<string | null>(crmRecord?.crm_record_name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const debouncedQuery = useDebounce(query, 750);

  useEffect(() => {
    setOptions([]);
    setIsLoading(!!query);
  }, [query]);

  useEffect(() => {
    if (!debouncedQuery) {
      setOptions([]);
      setIsLoading(false);
      return;
    }

    setCRMRecordSearchResultOptions();
  }, [debouncedQuery]);

  const setCRMRecordSearchResultOptions = async () => {
    try {
      const crmRecords = await getCRMRecordsByName(
        syncedCRMObject.crm_object_type,
        syncedCRMObject.searchable_crm_field_name,
        query
      );

      const options = crmRecords.map((crmRecord) => {
        return {
          label: crmRecord.crm_record_name,
          value: crmRecord.crm_record_id,
        };
      });

      setOptions(options);
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }

    setIsLoading(false);
  };

  return (
    <Form.Item layout="vertical" label={`${syncedCRMObject.crm_object_label}`}>
      {associatedCRMRecord?.auto_associated && (
        <Alert
          style={{ marginBottom: 12 }}
          message={
            <Typography.Text>
              <Typography.Text strong>
                {associatedCRMRecord.crm_record_name}
              </Typography.Text>{" "}
              (Suggested)
            </Typography.Text>
          }
          type="info"
          action={
            <Button
              size="small"
              type="primary"
              onClick={async () => {
                await onSelect({
                  crmRecordId: associatedCRMRecord.crm_record_id,
                  crmRecordName: associatedCRMRecord.crm_record_name,
                });

                setQuery(associatedCRMRecord.crm_record_name);
              }}
            >
              Accept
            </Button>
          }
        />
      )}

      {isIgnored ? (
        <Typography.Text>
          Ignored.{" "}
          <Typography.Link onClick={onUnignore}>
            Associate {syncedCRMObject.crm_object_label}
          </Typography.Link>
        </Typography.Text>
      ) : (
        <Row gutter={[6, 0]}>
          <Col span={20}>
            <AutoComplete
              id={`find-${syncedCRMObject.crm_provider_name}-${syncedCRMObject.crm_object_type}-autocomplete`}
              allowClear
              value={query}
              notFoundContent={
                isLoading ? (
                  <Spin size="small" style={{ padding: 8, width: "100%" }} />
                ) : query ? (
                  <Typography.Text>No results found.</Typography.Text>
                ) : null
              }
              options={options}
              onSelect={async (value, { label }) => {
                onSelect({ crmRecordId: value, crmRecordName: label });
                setQuery(label);
              }}
              onSearch={setQuery}
              placeholder={`Search for ${syncedCRMObject.crm_object_label}...`}
            />
          </Col>

          <Col span={4}>
            <Button onClick={onIgnore}>Ignore</Button>
          </Col>
        </Row>
      )}
    </Form.Item>
  );
}

interface OpportunitySearchAutocomplete {
  open: boolean;
  meeting: Meeting;
  layoutedFieldConfigurationGroups: LayoutedFieldConfigurationGroup[];
  hideReviewedRecords?: boolean;
  mutateLayoutedFieldConfigurationGroups: () => void;
  onClose: () => void;
}

export function AssociatedCRMRecordModal({
  open,
  meeting,
  layoutedFieldConfigurationGroups = [],
  hideReviewedRecords = false,
  mutateLayoutedFieldConfigurationGroups,
  onClose,
}: OpportunitySearchAutocomplete) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [lastSelectAt, setLastSelectAt] = useState<Date>(null);

  const pendingAssociationCount = layoutedFieldConfigurationGroups.filter(
    ({ pending_association }) => pending_association
  ).length;

  const syncedCRMObjects = layoutedFieldConfigurationGroups.map(
    ({ field_configuration_group: { synced_crm_object } }) =>
      synced_crm_object.crm_object_label
  );

  const closeable = pendingAssociationCount === 0 && !isSaving;

  useEffect(() => {
    if (!lastSelectAt || pendingAssociationCount > 0) return;

    setLastSelectAt(null);

    onClose();
  }, [pendingAssociationCount, lastSelectAt]);

  const unAcceptAutoGeneratedFields = async (
    syncedCRMObject: SyncedCRMObject
  ) => {
    const autoGeneratedFieldUuids = layoutedFieldConfigurationGroups
      .filter(
        ({ field_configuration_group }) =>
          field_configuration_group.synced_crm_object.uuid ===
          syncedCRMObject.uuid
      )
      .flatMap(({ layouted_fields }) => layouted_fields)
      .map(({ auto_generated_field }) => auto_generated_field?.uuid)
      .filter(Boolean);

    await fetchBulkUpdateAutoGeneratedFields(
      meeting.uuid,
      autoGeneratedFieldUuids,
      { accepted: null }
    );
  };

  const onSelect = async ({ syncedCRMObject, crmRecordId, crmRecordName }) => {
    setIsSaving(true);

    try {
      await createAssociatedCRMRecord({
        meetingUuid: meeting.uuid,
        syncedCrmObjectUuid: syncedCRMObject.uuid,
        crmRecordId,
        crmRecordName,
      });

      await unAcceptAutoGeneratedFields(syncedCRMObject);

      await mutateLayoutedFieldConfigurationGroups();

      setLastSelectAt(new Date());
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }

    setIsSaving(false);
  };

  return (
    <Modal
      title={`Associate ${
        syncedCRMObjects.length === 1 ? syncedCRMObjects[0] : "CRM Records"
      }`}
      open={open}
      onCancel={onClose}
      okButtonProps={{ disabled: !closeable }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onClose}
      okText="Close"
      maskClosable={closeable}
      closable={closeable}
      footer={
        <Row style={{ width: "100%" }} align="middle">
          {syncedCRMObjects.length > 1 && (
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography.Text>
                <Typography.Text strong>
                  {layoutedFieldConfigurationGroups.length -
                    pendingAssociationCount}{" "}
                  / {syncedCRMObjects.length}
                </Typography.Text>{" "}
                Records Associated
              </Typography.Text>
            </Col>
          )}

          <Col
            span={syncedCRMObjects.length > 1 ? 12 : 24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="primary" disabled={!closeable} onClick={onClose}>
              Close
            </Button>
          </Col>
        </Row>
      }
    >
      <Spin spinning={isSaving}>
        {layoutedFieldConfigurationGroups.map(
          ({
            field_configuration_group: { synced_crm_object },
            associated_crm_record,
            is_ignored,
            pending_association,
            crm_record,
          }) => {
            if (hideReviewedRecords && !pending_association) return;

            return (
              <CRMRecordSearch
                key={synced_crm_object.uuid}
                isIgnored={is_ignored}
                syncedCRMObject={synced_crm_object}
                crmRecord={crm_record}
                associatedCRMRecord={associated_crm_record}
                onSelect={({ crmRecordId, crmRecordName }) =>
                  onSelect({
                    syncedCRMObject: synced_crm_object,
                    crmRecordId,
                    crmRecordName,
                  })
                }
                onIgnore={async () => {
                  setIsSaving(true);

                  await createIgnoredSyncedCRMObject({
                    meetingUuid: meeting.uuid,
                    syncedCRMObjectUuid: synced_crm_object.uuid,
                  });

                  await unAcceptAutoGeneratedFields(synced_crm_object);

                  await mutateLayoutedFieldConfigurationGroups();

                  setLastSelectAt(new Date());
                  setIsSaving(false);
                }}
                onUnignore={async () => {
                  setIsSaving(true);

                  await deleteIgnoredSyncedCRMObject({
                    meetingUuid: meeting.uuid,
                    syncedCRMObjectUuid: synced_crm_object.uuid,
                  });

                  await mutateLayoutedFieldConfigurationGroups();

                  setLastSelectAt(new Date());
                  setIsSaving(false);
                }}
              />
            );
          }
        )}
      </Spin>
    </Modal>
  );
}

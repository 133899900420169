import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Modal,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkflow } from "../../../util/data_hooks";

import "reactflow/dist/style.css";
import { WorkflowInstances } from "./WorkflowInstances";
import { fetchDeleteWorkflow } from "../../../util/api";
import { Workflow } from "../../../util/types";
import { green, red } from "@ant-design/colors";
import {
  DeleteOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getFriendlyWorkflowTriggerLabel } from "../../../helpers/label_maps";
import { WorkflowDetailsEditorModal } from "./shared/WorkflowDetailsEditorModal";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useAppSelector } from "../../redux/hooks";
import { UserRole, WorkflowTrigger } from "../../../util/enums";
import { AppContext } from "../../App";

export function Workflow() {
  const { workflowUuid } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const team = useAppSelector((state) => state.team.selectedTeam);
  const teamLoading = useAppSelector((state) => state.team.isLoading);

  const { workflow, isLoading: workflowLoading } = useWorkflow(workflowUuid);

  const isLoading = workflowLoading || teamLoading;

  const isTechnicalAdmin = context?.user_roles?.includes(
    UserRole.TechnicalAdmin
  );

  usePageTitle(workflow?.name);

  const [workflowDetailsEditorModalOpen, setworkflowDetailsEditorModalOpen] =
    useState<boolean>(false);

  const onDeleteWorkflowClick = () => {
    const modal = Modal.confirm({
      title: `Delete Workflow: "${workflow.name}"?`,
      icon: <DeleteOutlined style={{ color: token.colorError }} />,
      content: "This cannot be undone.",
      okText: "Delete Workflow",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          modal.update({ okButtonProps: { loading: true } });
          await fetchDeleteWorkflow(workflowUuid);
          navigate("/dashboard/settings/workflows");
          message.success("Workflow deleted");
        } catch (e) {
          message.error(
            e.response?.data?.error ||
              "An error occurred. Please try again later."
          );
        }

        modal.update({ okButtonProps: { loading: false } });
      },
    });
  };

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <WorkflowDetailsEditorModal
        open={workflowDetailsEditorModalOpen}
        workflow={workflow}
        team={team}
        setOpen={setworkflowDetailsEditorModalOpen}
      />

      {isTechnicalAdmin && (
        <Breadcrumb
          style={{ marginBottom: 15 }}
          items={[
            {
              title: (
                <a onClick={() => navigate(`/dashboard/settings/workflows`)}>
                  Workflows
                </a>
              ),
            },
            { title: workflow.name },
          ]}
        />
      )}

      <Card
        title={
          <Space>
            {workflow.name}

            {workflow.enabled ? (
              <Tooltip title="Workflow Enabled">
                <CheckCircleTwoTone twoToneColor={green.primary} />
              </Tooltip>
            ) : (
              <Tooltip title="Workflow Disabled">
                <CloseCircleTwoTone twoToneColor={red.primary} />
              </Tooltip>
            )}
          </Space>
        }
        extra={
          isTechnicalAdmin && (
            <Space>
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ marginRight: "0.5rem" }}
                onClick={() => setworkflowDetailsEditorModalOpen(true)}
              />

              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={onDeleteWorkflowClick}
              />
            </Space>
          )
        }
        actions={
          isTechnicalAdmin && [
            <Button
              key="workflow-builder-button"
              style={{ width: 300 }}
              onClick={() => navigate("editor")}
              type="primary"
            >
              Enter Workflow Builder
            </Button>,
          ]
        }
      >
        <Descriptions>
          <Descriptions.Item label="Trigger">
            {getFriendlyWorkflowTriggerLabel(workflow.trigger)}
          </Descriptions.Item>

          {[WorkflowTrigger.CRMRecordUpdated].includes(workflow?.trigger) && (
            <Descriptions.Item label="CRM Field Triggers">
              <Row gutter={8}>
                {workflow?.field_definition_triggers.map(
                  ({ uuid, label, synced_crm_object }) => (
                    <Col key={uuid}>
                      <Typography.Link
                        key={uuid}
                        onClick={() =>
                          navigate(
                            `/dashboard/settings/field-configurations/${uuid}`
                          )
                        }
                      >
                        {label} ({synced_crm_object.crm_object_label})
                      </Typography.Link>
                    </Col>
                  )
                )}
              </Row>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>

      <Divider />

      <WorkflowInstances />
    </>
  );
}

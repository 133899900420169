import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  Divider,
  message,
  Modal,
  Skeleton,
  Space,
  Tabs,
  theme,
  Tooltip,
} from "antd";
import {
  SettingOutlined,
  PlusOutlined,
  CopyOutlined,
  FolderOpenOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useTeams, useFieldDefinition } from "../../../../util/data_hooks";
import { get } from "lodash";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { Page404 } from "../../shared/Page404";
import { FieldDefinitionWithUsage, Team } from "../../../../util/types";
import { FieldConfiguration } from "./FieldConfiguration";
import { FieldDefinitionEditorModal } from "../FieldDefinitionEditorModal";
import { EmptyFieldConfiguration } from "./configuration_management/EmptyFieldConfiguration";
import { EditFieldConfigurationDrawer } from "./configuration_management/EditFieldConfigurationDrawer";
import { deactivateFieldDefinition } from "../../../../util/api";
import { createFieldConfigurationModal } from "./configuration_management/CreateFieldConfigurationModal";
import { StaleAlert } from "./configuration_management/StaleAlert";
import { usePlaygroundMeetingFilter } from "./field_testing/usePlaygroundMeetingFilter";
import { useAppDispatch } from "../../../redux/hooks";
import { setCurrentFieldConfigurationTab } from "../../../redux/features/PlaygroundFieldTesting/playgroundSlice";

const getTabLabel = (
  fieldConfigUuid: string,
  teamMap: { [teamUuid: string]: Team },
  fieldDefinition: FieldDefinitionWithUsage
) => {
  const fieldConfigTeams = fieldDefinition.configuration_usage[fieldConfigUuid];
  const name = fieldConfigTeams
    .map((teamUuid) => {
      return teamMap[teamUuid].name;
    })
    .join(", ");
  return name || "Unassigned";
};

export function FieldConfigurationPage() {
  const { fieldGroupUuid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const fieldConfigurationUuidParam = searchParams.get("configuration");
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [isFieldConfigEditModalOpen, setFieldConfigEditModalOpen] =
    useState(false);
  const [selectedFieldConfigUuid, setFieldConfigUuid] = useState<string | null>(
    fieldConfigurationUuidParam || null
  );
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);
  const [
    refreshFieldConfigurationTrigger,
    setRefreshFieldConfigurationTrigger,
  ] = useState(0);
  const { resetFilters, updateTeamFilterUuids } = usePlaygroundMeetingFilter();

  const {
    fieldDefinition,
    isLoading: isFieldDefinitionLoading,
    mutate: refreshFieldDefinition,
  } = useFieldDefinition(fieldGroupUuid);

  useEffect(() => {
    // Set initial field config uuid
    if (selectedFieldConfigUuid === null && fieldDefinition) {
      const fieldConfigUuids = Object.keys(
        fieldDefinition?.configuration_usage || {}
      );
      if (fieldConfigUuids.length > 0) {
        setFieldConfigUuid(fieldConfigUuids[0]);
      }
    }
  }, [fieldDefinition]);

  useEffect(() => {
    // Set playground team filter based on selected field config
    if (selectedFieldConfigUuid && fieldDefinition) {
      resetFilters();
      updateTeamFilterUuids(
        fieldDefinition.configuration_usage[selectedFieldConfigUuid]
      );
    }
  }, [selectedFieldConfigUuid, fieldDefinition]);

  const { teams, isLoading: isTeamsLoading, mutate: refreshTeams } = useTeams();
  const teamMap = teams.reduce((acc, team) => {
    acc[team.uuid] = team;
    return acc;
  }, {});

  usePageTitle(fieldDefinition?.label);

  const isLoading = isFieldDefinitionLoading || isTeamsLoading;

  const showDeactivateConfirm = () => {
    Modal.confirm({
      title: `Deactivate ${fieldDefinition.label}?`,
      icon: <FolderOpenOutlined style={{ color: token.colorError }} />,
      content:
        "Field configuration will be deactivated. Workflows depending on this field may not function correctly.",
      okText: "Deactivate",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deactivateFieldDefinition(fieldDefinition.uuid);
          message.success("Field configuration deactivated!");
          navigate(`/dashboard/settings/field-configurations`);
        } catch (err) {
          const defaultMessage =
            "Oops, something is wrong on our end! Please try again later.";

          const is500 = get(err, "response.status", 500) >= 500;

          message.error(
            is500
              ? defaultMessage
              : get(err, "response.data.error", defaultMessage)
          );
        }
      },
    });
  };
  if (isLoading) return <Skeleton active />;

  if (!isFieldDefinitionLoading && fieldDefinition === null) return <Page404 />;

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 20 }}
        items={[
          {
            title: (
              <a
                onClick={() =>
                  navigate(`/dashboard/settings/field-configurations`)
                }
              >
                Field Configurations
              </a>
            ),
          },
          {
            title: isLoading ? (
              <Skeleton.Input style={{ width: 200 }} active />
            ) : (
              fieldDefinition.label
            ),
          },
        ]}
      />
      <StaleAlert
        fieldDefinition={fieldDefinition}
        refreshFieldDefinition={refreshFieldDefinition}
      />

      <Card
        title={
          isLoading ? (
            <Skeleton.Input style={{ width: 200 }} active />
          ) : fieldDefinition.synced_crm_object ? (
            "CRM Field Info"
          ) : (
            "Swyft Field Info"
          )
        }
        loading={isLoading}
        extra={
          !isLoading &&
          !fieldDefinition.synced_crm_object && (
            <div>
              <Button
                id={`field-definition-edit-${fieldDefinition.name}`}
                variant={"outlined"}
                color={"primary"}
                icon={<EditOutlined />}
                style={{ marginRight: "0.5rem" }}
                onClick={() => setFieldConfigEditModalOpen(true)}
              />
              {fieldDefinition.standard_field ? (
                <Tooltip title="Standard Swyft fields cannot be deactivated.">
                  <Button
                    id={`field-definition-deactivate-${fieldDefinition.name}`}
                    type="default"
                    danger
                    icon={<FolderOpenOutlined />}
                    disabled
                  />
                </Tooltip>
              ) : (
                <Button
                  id={`field-definition-deactivate-${fieldDefinition.name}`}
                  type="default"
                  danger
                  icon={<FolderOpenOutlined />}
                  onClick={showDeactivateConfirm}
                />
              )}
            </div>
          )
        }
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Descriptions>
            {fieldDefinition.synced_crm_object && (
              <Descriptions.Item label="CRM Object">
                {fieldDefinition.synced_crm_object?.crm_object_label}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="Label">
              {fieldDefinition.label}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {fieldDefinition.name}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {fieldDefinition.field_type}
            </Descriptions.Item>
            <Descriptions.Item label="Length">
              {fieldDefinition.length}
            </Descriptions.Item>
            <Descriptions.Item label="Is Required">
              {fieldDefinition.required ? "True" : "False"}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
      <Divider />
      <Tabs
        activeKey={selectedFieldConfigUuid}
        tabBarExtraContent={
          <Space>
            <Button
              id={`field-configuration-create`}
              icon={
                selectedFieldConfigUuid ? <CopyOutlined /> : <PlusOutlined />
              }
              onClick={() =>
                createFieldConfigurationModal({
                  fieldDefinitionUuid: fieldDefinition.uuid,
                  copyConfigUuid: selectedFieldConfigUuid,
                  onCreate: async (newFieldConfig) => {
                    await refreshFieldDefinition();
                    setFieldConfigUuid(newFieldConfig.uuid);
                  },
                })
              }
            />
            {selectedFieldConfigUuid && (
              <Button
                id={`field-configuration-edit`}
                icon={<SettingOutlined />}
                onClick={() => setEditDrawerOpen(true)}
              />
            )}
          </Space>
        }
        onChange={(key) => {
          setFieldConfigUuid(key);
          dispatch(setCurrentFieldConfigurationTab(key));
          setSearchParams({ configuration: key });
        }}
        items={Object.keys(fieldDefinition?.configuration_usage || {}).map(
          (fieldConfigUuid) => {
            return {
              label: getTabLabel(fieldConfigUuid, teamMap, fieldDefinition),
              key: fieldConfigUuid,
              children: (
                <FieldConfiguration
                  fieldDefinition={fieldDefinition}
                  fieldConfigurationUuid={fieldConfigUuid}
                  allTeams={teams}
                  refreshData={refreshTeams}
                  refreshTrigger={refreshFieldConfigurationTrigger}
                />
              ),
            };
          }
        )}
      />

      {Object.keys(fieldDefinition?.configuration_usage || {}).length === 0 && (
        <EmptyFieldConfiguration
          onCreate={() =>
            createFieldConfigurationModal({
              fieldDefinitionUuid: fieldDefinition.uuid,
              copyConfigUuid: selectedFieldConfigUuid,
              onCreate: async (newFieldConfig) => {
                await refreshFieldDefinition();
                setFieldConfigUuid(newFieldConfig.uuid);
              },
            })
          }
        />
      )}

      {isEditDrawerOpen && (
        <EditFieldConfigurationDrawer
          fieldDefinition={fieldDefinition}
          fieldConfigurationUuid={selectedFieldConfigUuid}
          allTeams={teams}
          isOpen={isEditDrawerOpen}
          setIsOpen={setEditDrawerOpen}
          onEdit={() => {
            refreshFieldDefinition();
            refreshTeams();
          }}
          onDelete={async () => {
            setFieldConfigUuid(null);
            await Promise.all([refreshFieldDefinition(), refreshTeams()]);
          }}
        />
      )}

      {isFieldConfigEditModalOpen && (
        <FieldDefinitionEditorModal
          open={isFieldConfigEditModalOpen}
          fieldDefinition={fieldDefinition}
          setOpen={setFieldConfigEditModalOpen}
          onEdit={() => {
            refreshFieldDefinition().then(); // Ignore the promise
            setRefreshFieldConfigurationTrigger(
              refreshFieldConfigurationTrigger + 1
            );
          }}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  Typography,
  Space,
  Row,
  Col,
  Empty,
  Skeleton,
  Divider,
  Tabs,
  Flex,
} from "antd";
import {
  useFieldConfigurationGroups,
  useOrganization,
} from "../../../util/data_hooks";
import { useParams } from "react-router-dom";
import { FieldConfigurationsTable } from "./FieldConfigurationsTable";
import { FieldConfigurationGroupModal } from "./FieldConfigurationGroupModal";
import { usePageTitle } from "../../hooks/usePageTitle";
import { ContextTab } from "./field_configuration/context_tab/ContextTab";
import { teamColors } from "../../../helpers/teamColorHelper";
import { FieldConfigurationsFilters } from "./filters/FieldConfigurationsFilters";
import { useFieldConfigurationsFilters } from "./filters/useFieldConfigurationsFilters";

enum ConfigurationTab {
  FieldConfigurations = "field-configurations",
  Context = "context",
}

export function FieldConfigurations() {
  usePageTitle("Field Configurations");

  const { organizationUuid } = useParams();

  const {
    teams,
    isLoading: teamsLoading,
    mutate: refreshTeams,
  } = useFieldConfigurationsFilters();
  const assignedTeamColors = teamColors(teams);

  const [syncCRMObjectModalOpen, setSyncCRMObjectModalOpen] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigurationTab>(
    ConfigurationTab.FieldConfigurations
  );

  const {
    organization,
    isLoading: isOrganizationLoading,
    mutate: refreshOrganization,
  } = useOrganization(organizationUuid);

  const {
    fieldConfigurationGroups,
    isLoading: isTeamFieldConfigurationGroupsLoading,
    mutate: mutateTeamFieldConfigurationGroups,
  } = useFieldConfigurationGroups();

  const isLoading =
    isOrganizationLoading ||
    teamsLoading ||
    isTeamFieldConfigurationGroupsLoading;

  if (isLoading) return <Skeleton active />;

  return (
    <>
      {syncCRMObjectModalOpen && (
        <FieldConfigurationGroupModal
          open={syncCRMObjectModalOpen}
          organization={organization}
          onClose={() => setSyncCRMObjectModalOpen(false)}
          onSave={() => {
            mutateTeamFieldConfigurationGroups();
            setSyncCRMObjectModalOpen(false);
          }}
        />
      )}

      <Tabs
        activeKey={activeTab}
        defaultActiveKey={ConfigurationTab.FieldConfigurations}
        onChange={(key) => setActiveTab(key as ConfigurationTab)}
        items={[
          {
            key: ConfigurationTab.FieldConfigurations,
            label: "Field Configurations",
            children: (
              <>
                <Row justify="space-between">
                  <Col span={24}>
                    <Flex justify={"flex-end"}>
                      <FieldConfigurationsFilters
                        assignedTeamColors={assignedTeamColors}
                        createNewGroup={() => setSyncCRMObjectModalOpen(true)}
                      />
                    </Flex>
                  </Col>
                </Row>

                <>
                  {fieldConfigurationGroups.length > 0 ? (
                    <>
                      {fieldConfigurationGroups?.map(
                        (fieldConfigurationGroup) => {
                          return (
                            <div key={fieldConfigurationGroup.uuid}>
                              <FieldConfigurationsTable
                                teams={teams}
                                teamColors={assignedTeamColors}
                                fieldConfigurationGroup={
                                  fieldConfigurationGroup
                                }
                                onSave={() =>
                                  mutateTeamFieldConfigurationGroups()
                                }
                              />

                              <Divider />
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <Empty
                      style={{ marginTop: 200 }}
                      description={
                        <Space direction="vertical">
                          <Typography.Title level={5}>
                            No Field Configurations Found
                          </Typography.Title>

                          <Button
                            onClick={() => setSyncCRMObjectModalOpen(true)}
                          >
                            Create Field Configuration Group
                          </Button>
                        </Space>
                      }
                    />
                  )}
                </>
              </>
            ),
          },
          {
            key: ConfigurationTab.Context,
            label: "Context",
            children: (
              <ContextTab
                organization={organization}
                allTeams={teams}
                refreshTeams={refreshTeams}
                refreshOrganization={refreshOrganization}
              />
            ),
          },
        ]}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Divider, Form, Skeleton, Typography } from "antd";
import { WorkflowStateEditorFieldName } from "../shared/WorkflowStateEditorDrawer";
import { useCRMObjectFields } from "../../../../util/data_hooks";
import { CRMObject, CRMProvider } from "../../../../util/types";
import { WorkflowQueryEditor } from "./shared/WorkflowQueryEditor";
import { CRMObjectSelectFormItem } from "./shared/CRMObjectSelectFormItem";
import { CRMSelectFormItem } from "./shared/CRMSelectFormItem";
import { WorkflowVariableName } from "../../../../util/enums";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";

export function FindCRMRecord() {
  const form = Form.useFormInstance();

  const [selectedCRM, setSelectedCRM] = useState<CRMProvider>(null);
  const [selectedCRMObject, setSelectedCRMObject] = useState<CRMObject>(null);

  const { objectFields, isLoading: isCRMObjectFieldsLoading } =
    useCRMObjectFields(selectedCRM, selectedCRMObject?.crm_object_name, true);

  useEffect(() => {
    form.setFieldValue(
      WorkflowStateEditorFieldName.Name,
      `Find ${getFriendlyIntegrationLabel(selectedCRM) || "CRM"} ${
        selectedCRMObject?.crm_object_label || "Record"
      }`
    );
  }, [selectedCRM, selectedCRMObject]);

  if (isCRMObjectFieldsLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Select Object Type</Typography.Title>
        <Typography.Text type="secondary">
          What type of record should be found?
        </Typography.Text>
      </div>

      <CRMSelectFormItem onChange={setSelectedCRM} />

      <CRMObjectSelectFormItem
        crm={selectedCRM}
        onChange={setSelectedCRMObject}
      />

      <Divider />

      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4}>Build Query Attributes</Typography.Title>

        <Typography.Text type="secondary">
          Add attributes and values that can be used to find the record.
        </Typography.Text>
      </div>

      <Form.Item
        label="Query"
        name={[
          WorkflowStateEditorFieldName.Variables,
          WorkflowVariableName.Query,
        ]}
      >
        <WorkflowQueryEditor
          defaultQuery={form.getFieldValue([
            WorkflowStateEditorFieldName.Variables,
            WorkflowVariableName.Query,
          ])}
          onChange={(query) =>
            form.setFieldValue(
              [
                WorkflowStateEditorFieldName.Variables,
                WorkflowVariableName.Query,
              ],
              query
            )
          }
          fields={objectFields.map((objectField) => {
            return {
              name: objectField.crm_field_name,
              label: objectField.crm_field_label,
            };
          })}
        />
      </Form.Item>
    </>
  );
}

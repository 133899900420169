import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TeamDrawerTab } from "../../../../util/enums";

export interface TeamDrawerTabSlice {
  selectedTab: TeamDrawerTab;
}

const initialState: TeamDrawerTabSlice = {
  selectedTab: TeamDrawerTab.Edit,
};

export const teamDrawerTabSlice = createSlice({
  name: "teamDrawerTab",
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<TeamDrawerTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTab } = teamDrawerTabSlice.actions;

export default teamDrawerTabSlice.reducer;

import React, { useEffect, useState } from "react";
import { LayoutedFieldConfigurationGroup, Meeting } from "../../util/types";
import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { AutoGeneratedFieldCard } from "./auto_generated_field/AutoGeneratedFieldCard";
import { SwyftAutoGeneratedFieldCard } from "./auto_generated_field/SwyftAutoGeneratedFieldCard";
import { MeetingFormFieldItem } from "./MeetingFormFieldItem";
import { CRMRecordTag } from "../shared/CRMRecordTag";
import { AssociatedCRMRecordModal } from "./AssociatedCRMRecordModal";
import { SearchOutlined } from "@ant-design/icons";
import { SwyftIcon } from "../shared/SwyftIcon";
import { parseCRMValue } from "../../util/parseCRMValue";
import { deleteIgnoredSyncedCRMObject } from "../../util/api";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import { usePendingAutoGeneratedField } from "./hooks/usePendingAutoGeneratedField";
import { InfoCircleOutlined } from "@ant-design/icons";

interface MeetingFormFieldsProps {
  meeting: Meeting;
  layoutedFieldConfigurationGroup: LayoutedFieldConfigurationGroup;
  mutateLayoutedFieldConfigurationGroups: () => void;
}

export function MeetingFormFields({
  meeting,
  layoutedFieldConfigurationGroup,
  mutateLayoutedFieldConfigurationGroups,
}: MeetingFormFieldsProps) {
  const { field_configuration_group, crm_record, layouted_fields } =
    layoutedFieldConfigurationGroup;

  const syncedCRMObject = field_configuration_group.synced_crm_object;

  const form = Form.useFormInstance();

  const [isUnignoring, setIsUnignoring] = useState<boolean>(false);

  const [associatedCRMRecordModalOpen, setAssociatedCRMRecordModalOpen] =
    useState<boolean>(false);

  const { scrollToPendingAutoGeneratedField } = usePendingAutoGeneratedField([
    layoutedFieldConfigurationGroup,
  ]);

  useEffect(() => {
    const values = layouted_fields.reduce(
      (obj, { crm_field, auto_generated_field, field_definition }) => {
        if (field_definition.crm_managed) {
          const { crm_field_name, crm_field_value, crm_field_type } = crm_field;

          const currentFieldValue = form.getFieldValue([
            field_configuration_group.uuid,
            crm_field_name,
          ]);

          const parsedCRMValue = parseCRMValue(crm_field_type, crm_field_value);

          let value;

          if (auto_generated_field) {
            value = auto_generated_field.accepted
              ? auto_generated_field.content
              : parsedCRMValue;
          } else if (isNullOrUndefined(currentFieldValue)) {
            value = parsedCRMValue;
          }

          if (isNullOrUndefined(value)) return obj;

          return { ...obj, [crm_field_name]: value };
        } else {
          return {
            ...obj,
            [field_definition.name]: auto_generated_field?.content_present
              ? parseCRMValue(
                  field_definition.field_type,
                  auto_generated_field.content
                )
              : null,
          };
        }
      },
      {}
    );

    form.setFieldsValue({ [field_configuration_group.uuid]: values });
  }, [layoutedFieldConfigurationGroup]);

  return (
    <div>
      {syncedCRMObject && (
        <AssociatedCRMRecordModal
          open={associatedCRMRecordModalOpen}
          meeting={meeting}
          layoutedFieldConfigurationGroups={[layoutedFieldConfigurationGroup]}
          mutateLayoutedFieldConfigurationGroups={
            mutateLayoutedFieldConfigurationGroups
          }
          onClose={() => {
            setAssociatedCRMRecordModalOpen(false);

            scrollToPendingAutoGeneratedField();
          }}
        />
      )}

      <Affix offsetTop={15} style={{ marginBottom: 15, width: "40%" }}>
        <Card
          size="small"
          style={{
            boxShadow: "5px 3px 3px rgba(140, 140, 140, 0.25)",
            border: "none",
            overflow: "hidden",
          }}
        >
          <Row
            align="middle"
            gutter={[14, 12]}
            justify="space-between"
            wrap={false}
          >
            <Col span={14}>
              <Typography.Title
                level={4}
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                ellipsis
              >
                {field_configuration_group.label}
              </Typography.Title>
            </Col>

            <Col
              span={10}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {field_configuration_group.synced_crm_object ? (
                <>
                  {layoutedFieldConfigurationGroup.is_ignored ? (
                    <Button
                      size="small"
                      loading={isUnignoring}
                      disabled={isUnignoring}
                      type="dashed"
                      onClick={async () => {
                        setIsUnignoring(true);

                        await deleteIgnoredSyncedCRMObject({
                          meetingUuid: meeting.uuid,
                          syncedCRMObjectUuid: syncedCRMObject.uuid,
                        });

                        await mutateLayoutedFieldConfigurationGroups();

                        const hasAssociation =
                          layoutedFieldConfigurationGroup.crm_record
                            .crm_record_id;

                        if (hasAssociation) {
                          setAssociatedCRMRecordModalOpen(true);
                        }

                        setIsUnignoring(false);
                      }}
                    >
                      Associate {syncedCRMObject.crm_object_label}
                    </Button>
                  ) : (
                    <Space>
                      <Tooltip
                        title={`Select a different ${field_configuration_group.synced_crm_object.crm_object_label}`}
                      >
                        <Button
                          size="small"
                          shape="circle"
                          icon={<SearchOutlined />}
                          onClick={() => setAssociatedCRMRecordModalOpen(true)}
                        />
                      </Tooltip>

                      <CRMRecordTag
                        showBackground
                        crm={crm_record.crm}
                        name={crm_record.crm_record_name}
                        url={crm_record.crm_record_url}
                      />
                    </Space>
                  )}
                </>
              ) : (
                <SwyftIcon inverted width={25} />
              )}
            </Col>
          </Row>
        </Card>
      </Affix>

      {!layoutedFieldConfigurationGroup.is_ignored && (
        <Row gutter={[12, 24]}>
          {layouted_fields.map(
            ({
              field_configuration,
              field_definition,
              auto_generated_field,
              crm_field,
              changes_detected,
            }) => {
              if (
                field_definition.crm_managed &&
                field_configuration.automated &&
                auto_generated_field &&
                crm_field
              ) {
                return (
                  <Col span={24} key={field_configuration.uuid}>
                    <AutoGeneratedFieldCard
                      name={[
                        field_configuration_group.uuid,
                        crm_field.crm_field_name,
                      ]}
                      meeting={meeting}
                      fieldDefinition={field_definition}
                      autoGeneratedField={auto_generated_field}
                      crmRecord={crm_record}
                      crmField={crm_field}
                      changesDetected={changes_detected}
                      mutateLayoutedFieldConfigurationGroups={
                        mutateLayoutedFieldConfigurationGroups
                      }
                      onSelectCRMRecordClick={() =>
                        setAssociatedCRMRecordModalOpen(true)
                      }
                    />
                  </Col>
                );
              } else if (field_definition.crm_managed && crm_field) {
                return (
                  <Col span={12} key={field_configuration.uuid}>
                    <Card
                      title={
                        <Space>
                          {crm_field.crm_field_label}

                          <Tooltip
                            title={
                              crm_record.crm_record_id
                                ? "Please manually enter data for this CRM field"
                                : `Please select the associated ${field_configuration_group.synced_crm_object.crm_object_label} for this meeting`
                            }
                          >
                            <InfoCircleOutlined style={{ fontSize: "12px" }} />
                          </Tooltip>
                        </Space>
                      }
                    >
                      <MeetingFormFieldItem
                        name={[
                          field_configuration_group.uuid,
                          crm_field.crm_field_name,
                        ]}
                        label={crm_field.crm_field_label}
                        fieldType={crm_field.crm_field_type}
                        required={
                          crm_field.crm_field_required &&
                          !!crm_record.crm_record_id
                        }
                        length={crm_field.crm_field_length}
                        picklistItems={crm_field.crm_field_picklist_values}
                        disabled={!crm_record.crm_record_id}
                      />
                    </Card>
                  </Col>
                );
              } else if (
                !field_definition.crm_managed &&
                field_configuration.automated &&
                auto_generated_field
              ) {
                return (
                  <Col span={12} key={field_configuration.uuid}>
                    <SwyftAutoGeneratedFieldCard
                      form={form}
                      meeting={meeting}
                      fieldDefinition={field_definition}
                      autoGeneratedField={auto_generated_field}
                      mutateLayoutedFieldConfigurationGroups={
                        mutateLayoutedFieldConfigurationGroups
                      }
                    />
                  </Col>
                );
              }
            }
          )}
        </Row>
      )}
    </div>
  );
}

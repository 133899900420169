import { message, Modal } from "antd";
import { FieldConfiguration } from "../../../../../util/types";
import { createFieldConfiguration } from "../../../../../util/api";
import { get } from "lodash";

interface Props {
  fieldDefinitionUuid: string;
  copyConfigUuid: string | null;
  onCreate: (newFieldConfig: FieldConfiguration) => void;
}

export function createFieldConfigurationModal({
  onCreate,
  copyConfigUuid,
  fieldDefinitionUuid,
}: Props) {
  Modal.confirm({
    title: copyConfigUuid
      ? `Duplicate current configuration`
      : `Create new field configuration?`,
    content: `New Field configuration will be created. Teams will need to be assigned to this configuration.${copyConfigUuid ? " Prompts will be copied from the currently selected configuration." : ""}`,
    okText: "Create",
    okType: "primary",
    cancelText: "Cancel",
    onOk: async () => {
      try {
        const newFieldConfig = await createFieldConfiguration(
          fieldDefinitionUuid,
          copyConfigUuid
        );
        message.success("Field configuration created!");
        onCreate(newFieldConfig);
      } catch (err) {
        const defaultMessage =
          "Oops, something is wrong on our end! Please try again later.";

        const is500 = get(err, "response.status", 500) >= 500;

        message.error(
          is500
            ? defaultMessage
            : get(err, "response.data.error", defaultMessage)
        );
      }
    },
  });
}

import { Col, Input, Modal, Radio, Row, Select, message } from "antd";
import React, { useState } from "react";
import {
  createFieldConfigurationGroup,
  createSyncedCRMObject,
} from "../../../util/api";
import { useCRMObjectFields, useCRMObjects } from "../../../util/data_hooks";
import {
  CRMField,
  CRMObject,
  CRMProvider,
  Organization,
  SyncedCRMObject,
} from "../../../util/types";

enum NewGroupSource {
  CRM = "crm",
  Custom = "custom",
}

export function FieldConfigurationGroupModal({
  open,
  organization,
  onClose,
  onSave,
}: {
  open: boolean;
  organization: Organization;
  onClose: () => void;
  onSave: () => void;
}) {
  const [newGroupSource, setNewGroupSource] = useState<NewGroupSource>(
    NewGroupSource.CRM
  );
  const [crmProviderName, setCRMProviderName] = useState<CRMProvider | null>(
    null
  );
  const [label, setLabel] = useState<string>(null);
  const [crmObject, setCRMObject] = useState<CRMObject>(null);
  const [searchableCRMField, setSearchableCRMField] = useState<CRMField | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { objects } = useCRMObjects(organization?.crm_provider_name);

  const { objectFields } = useCRMObjectFields(
    organization?.crm_provider_name,
    crmObject?.crm_object_name
  );

  return (
    <Modal
      title="New Field Configuration Group"
      open={open}
      onCancel={() => onClose()}
      onOk={async () => {
        setIsLoading(true);

        let syncedCRMObject: SyncedCRMObject = null;

        if (newGroupSource === NewGroupSource.CRM) {
          syncedCRMObject = await createSyncedCRMObject({
            organizationUuid: organization.uuid,
            crmProviderName,
            crmObjectType: crmObject.crm_object_name,
            crmObjectLabel: crmObject.crm_object_label,
            searchableCRMFieldName: searchableCRMField?.crm_field_name,
          });
        }

        try {
          await createFieldConfigurationGroup({
            syncedCRMObjectUuid: syncedCRMObject?.uuid,
            label,
          });
          onSave();
          message.success(`"${label}" Created`);
        } catch (e) {
          message.error(e.response.data.message);
        }

        setIsLoading(false);
      }}
      okText="Create Group"
      okButtonProps={{
        disabled:
          newGroupSource === NewGroupSource.CRM
            ? !crmObject || !searchableCRMField
            : !label,
        loading: isLoading,
      }}
    >
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <Radio.Group
            optionType="button"
            onChange={(e) => {
              setNewGroupSource(e.target.value);

              setLabel(null);
              setCRMProviderName(null);
              setCRMObject(null);
            }}
            defaultValue={newGroupSource}
            options={[
              {
                label: "Sync From CRM",
                value: NewGroupSource.CRM,
              },
              {
                label: "Create Custom Group",
                value: NewGroupSource.Custom,
              },
            ]}
          />
        </Col>

        {newGroupSource === NewGroupSource.CRM && (
          <>
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Search for an object..."
              options={objects.map(({ crm_object_label, crm_object_name }) => {
                return {
                  label: crm_object_label,
                  value: crm_object_name,
                };
              })}
              onSelect={(name: string) => {
                const object = objects.find(
                  ({ crm_object_name }) => crm_object_name === name
                );

                setCRMProviderName(organization?.crm_provider_name);
                setCRMObject(object);
                setLabel(object?.crm_object_label);
                setSearchableCRMField(null);
              }}
              style={{ width: "100%" }}
            />

            {crmObject && (
              <Select
                showSearch
                optionFilterProp="label"
                value={searchableCRMField?.crm_field_name}
                placeholder="Select the primary name field for this object"
                options={objectFields.map(
                  ({ crm_field_name, crm_field_label }) => {
                    return {
                      label: crm_field_label,
                      value: crm_field_name,
                    };
                  }
                )}
                onSelect={(name: string) => {
                  const field = objectFields.find(
                    ({ crm_field_name }) => crm_field_name === name
                  );

                  setSearchableCRMField(field);
                }}
                style={{ width: "100%" }}
              />
            )}
          </>
        )}

        {newGroupSource === NewGroupSource.Custom && (
          <Input
            placeholder="Group name"
            onChange={(e) => setLabel(e.target.value)}
          />
        )}
      </Row>
    </Modal>
  );
}

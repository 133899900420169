import { Card, Typography, message } from "antd";
import React from "react";
import { Connection, Handle, Position } from "reactflow";
import { WorkflowState } from "../../../util/types";
import { WorkflowActionType } from "../../../util/enums";
import { fetchCreateWorkflowTransition } from "../../../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkflow } from "../../../util/data_hooks";

interface WorkflowStateNodeProps {
  data: { workflowState: WorkflowState };
}

export function WorkflowStateNode(props: WorkflowStateNodeProps) {
  const {
    data: { workflowState },
  } = props;

  const navigate = useNavigate();

  const { workflowUuid } = useParams();
  const { mutate: mutateWorkflow } = useWorkflow(workflowUuid);

  const handleConnect = async (connection: Connection) => {
    try {
      await fetchCreateWorkflowTransition({
        workflow_state_uuid: connection.source,
        target_workflow_state_uuid: connection.target,
      });
    } catch (e) {
      message.error("Error connecting actions. Please try again");
    }

    mutateWorkflow();
  };

  const isTrigger = workflowState.action_type === WorkflowActionType.Trigger;

  return (
    <>
      {!isTrigger && (
        <Handle
          type="target"
          position={Position.Top}
          onConnect={handleConnect}
          isConnectable={!workflowState.source_workflow_transition}
        />
      )}

      <Card
        title={isTrigger ? "Trigger" : "Action"}
        size="small"
        style={{ width: 300, textAlign: "center" }}
        actions={[
          <Typography.Link
            key="new-action"
            onClick={() =>
              navigate(`workflow-states/${workflowState.uuid}/new`)
            }
          >
            New Action
          </Typography.Link>,
          <Typography.Link
            key="new-condition"
            onClick={() =>
              navigate(
                `workflow-states/${workflowState.uuid}/workflow-transitions/new`
              )
            }
          >
            New Condition
          </Typography.Link>,
        ]}
      >
        {isTrigger ? (
          <Typography.Text strong>{workflowState.name}</Typography.Text>
        ) : (
          <Typography.Link
            onClick={() => {
              navigate(`workflow-states/${workflowState.uuid}`);
            }}
          >
            {workflowState.name}
          </Typography.Link>
        )}
      </Card>

      <Handle
        type="source"
        position={Position.Bottom}
        onConnect={handleConnect}
      />
    </>
  );
}

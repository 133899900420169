import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import {
  CRMFieldType,
  CRMFieldTypeLabel,
  StandardFieldNames,
} from "../../../util/enums";
import { get } from "lodash";
import { createFieldDefinition, editFieldDefinition } from "../../../util/api";
import { FieldConfigurationGroup, FieldDefinition } from "../../../util/types";

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

interface CommonProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

interface CreateProps {
  fieldConfigurationGroup: FieldConfigurationGroup;
  onCreate?: (fieldDefinition: FieldDefinition) => void;
}

interface EditProps {
  fieldDefinition: FieldDefinition;
  onEdit?: (fieldDefinition: FieldDefinition) => void;
}

type FieldType = {
  name: string;
  type: CRMFieldType;
  length?: number;
  picklist_options?: string[];
};

const fieldTypeOptions: { label: string; value: string }[] = [
  {
    label: CRMFieldTypeLabel.Textarea,
    value: CRMFieldType.Textarea,
  },
  {
    label: CRMFieldTypeLabel.Boolean,
    value: CRMFieldType.Boolean,
  },
  {
    label: CRMFieldTypeLabel.Picklist,
    value: CRMFieldType.Picklist,
  },
  {
    label: CRMFieldTypeLabel.Multipicklist,
    value: CRMFieldType.Multipicklist,
  },
  {
    label: CRMFieldTypeLabel.Double,
    value: CRMFieldType.Double,
  },
  {
    label: CRMFieldTypeLabel.Date,
    value: CRMFieldType.Date,
  },
  {
    label: CRMFieldTypeLabel.DateTime,
    value: CRMFieldType.DateTime,
  },
];

export function FieldDefinitionEditorModal({
  open,
  fieldDefinition,
  fieldConfigurationGroup,
  onCreate,
  onEdit,
  setOpen,
}: XOR<CreateProps, EditProps> & CommonProps) {
  const [form] = Form.useForm();
  const typeValue = Form.useWatch("type", form);
  const [isLoading, setLoading] = useState(false);
  const createMode = fieldDefinition === undefined;
  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };
  const handleEdit = async (values) => {
    setLoading(true);

    try {
      const modifiedFieldDef = await editFieldDefinition(
        fieldDefinition.uuid,
        values
      );
      message.success("Field configuration updated!");

      if (onEdit) onEdit(modifiedFieldDef);
      form.resetFields();
      closeModal();
    } catch (err) {
      console.log(err);
      await handleError(err);
    }

    setLoading(false);
  };

  const handleCreate = async (values) => {
    setLoading(true);

    try {
      const newFieldConfig = await createFieldDefinition({
        ...values,
      });

      message.success("Automation Created");

      if (onCreate) onCreate(newFieldConfig);
      form.resetFields();
      closeModal();
    } catch (err) {
      await handleError(err);
    }

    setLoading(false);
  };

  const handleError = async (err) => {
    const defaultMessage =
      "Oops, something is wrong on our end! Please try again later.";

    const is500 = get(err, "response.status", 500) >= 500;
    message.error(
      is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
    );
  };

  return (
    <Modal
      title={createMode ? `Create Custom Automation` : `Edit Automation`}
      onCancel={closeModal}
      open={open}
      footer={false}
      width={"60%"}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={createMode ? handleCreate : handleEdit}
        initialValues={
          createMode
            ? { field_configuration_group_uuid: fieldConfigurationGroup?.uuid }
            : {
                field_configuration_group_uuid:
                  fieldDefinition.field_configuration_group.uuid,
                name: fieldDefinition.label,
                type: fieldDefinition.field_type,
                length: fieldDefinition.length,
                picklist_options: fieldDefinition.picklist_definitions.map(
                  (p) => p.label
                ),
              }
        }
      >
        <Form.Item name="field_configuration_group_uuid" hidden={true} />

        <Form.Item<Partial<FieldType>>
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter a name"
            disabled={
              fieldDefinition?.standard_field &&
              fieldDefinition?.name === StandardFieldNames.Summary
            }
          />
        </Form.Item>

        <Form.Item<Partial<FieldType>>
          label="Field Type"
          name="type"
          rules={[{ required: true }]}
        >
          <Select
            id="field-type-select"
            options={fieldTypeOptions}
            disabled={!createMode}
          />
        </Form.Item>

        {[CRMFieldType.Multipicklist, CRMFieldType.Picklist].includes(
          typeValue
        ) && (
          <Form.Item<Partial<FieldType>>
            label="Picklist Options"
            name="picklist_options"
            rules={[{ required: true }]}
          >
            <Select
              id="picklist-options-select"
              mode="tags"
              placeholder="Enter a picklist option and press enter"
            />
          </Form.Item>
        )}

        {CRMFieldType.Textarea == typeValue && (
          <Form.Item<Partial<FieldType>>
            label="Max Length"
            name="length"
            rules={[{ required: true }]}
            tooltip={
              "Enter the maximum amount of characters that this field can contain. Must be between 1-32000"
            }
          >
            <InputNumber min={1} max={32000} />
          </Form.Item>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>

          <Button onClick={closeModal}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

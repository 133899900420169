import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import { WorkflowState, WorkflowTransition } from "../../../../util/types";
import {
  fetchCreateWorkflowState,
  fetchCreateWorkflowTransition,
  fetchDeleteWorkflowState,
  fetchUpdateWorkflowState,
  fetchUpdateWorkflowTransition,
} from "../../../../util/api";
import { getFriendlyWorkflowActionLabel } from "../../../../helpers/label_maps";
import { WorkflowActionType } from "../../../../util/enums";
import { useForm } from "antd/es/form/Form";
import { FindCRMRecord } from "../action_editors/FindCRMRecord";
import { UpdateCRMRecord } from "../action_editors/UpdateCRMRecord";
import { CreateCRMRecord } from "../action_editors/CreateCRMRecord";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import {
  useWorkflow,
  useWorkflowState,
  useWorkflowTransition,
} from "../../../../util/data_hooks";
import { SendSlackMessage } from "../action_editors/SendSlackMessage";
import { CreateSlackChannel } from "../action_editors/CreateSlackChannel";
import { SendMicrosoftTeamsMessage } from "../action_editors/SendMicrosoftTeamsMessage";

export enum WorkflowStateEditorFieldName {
  Name = "name",
  ActionType = "action_type",
  Variables = "variables",
}

function ActionEditor({
  selectedActionType,
  workflowState,
}: {
  selectedActionType: WorkflowActionType;
  workflowState: WorkflowState;
}) {
  switch (selectedActionType) {
    case WorkflowActionType.FindCRMRecord:
      return <FindCRMRecord />;
    case WorkflowActionType.UpdateCRMRecord:
      return <UpdateCRMRecord workflowState={workflowState} />;
    case WorkflowActionType.CreateCRMRecord:
      return <CreateCRMRecord workflowState={workflowState} />;
    case WorkflowActionType.CreateSlackChannel:
      return <CreateSlackChannel />;
    case WorkflowActionType.SendSlackMessage:
      return <SendSlackMessage />;
    case WorkflowActionType.SendMicrosoftTeamsMessage:
      return <SendMicrosoftTeamsMessage />;
    default:
      return null;
  }
}

interface WorkflowStateEditorDrawerProps {
  isNew?: boolean;
}

export function WorkflowStateEditorDrawer({
  isNew,
}: WorkflowStateEditorDrawerProps) {
  const [form] = useForm();
  const navigate = useNavigate();

  const { workflowUuid, workflowStateUuid } = useParams();
  const [searchParams] = useSearchParams();

  const {
    workflow,
    isLoading: workflowLoading,
    mutate: mutateWorkflow,
  } = useWorkflow(workflowUuid);

  const {
    workflowState,
    isLoading: workflowStateLoading,
    mutate: mutateWorkflowState,
  } = useWorkflowState(workflowUuid, workflowStateUuid);

  const { workflowTransition, isLoading: workflowTransitionLoading } =
    useWorkflowTransition(searchParams.get("workflowTransitionUuid"));

  const [open, setOpen] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedActionType, setSelectedActionType] =
    useState<WorkflowActionType>();
  const [actionName, setActionName] = useState<string>(null);

  const isLoading =
    workflowLoading || workflowStateLoading || workflowTransitionLoading;

  useEffect(() => {
    if (isLoading) return;

    if (isNew) {
      setSelectedActionType(null);
      const defaultName = "New Action";
      form.setFieldValue(WorkflowStateEditorFieldName.Name, defaultName);
      setActionName(defaultName);
    } else {
      form.setFieldsValue(workflowState);
      setSelectedActionType(workflowState.action_type);
      setActionName(workflowState.name);
    }
  }, [open, isLoading]);

  Form.useWatch((formValues) => {
    const name = formValues?.name;
    if (name) setActionName(name);
  }, form);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    await form.validateFields();

    setIsSaving(true);

    try {
      const { name, action_type, variables } = values;

      const workflowStateValues: Partial<WorkflowState> = {
        name,
        action_type,
        variables,
      };

      if (isNew) {
        const newWorkflowState = await fetchCreateWorkflowState(
          workflowUuid,
          workflowStateValues
        );

        if (workflowTransition) {
          const updateWorkflowTransitionValues: Partial<WorkflowTransition> =
            searchParams.get("isFallback")
              ? { fallback_target_workflow_state_uuid: newWorkflowState.uuid }
              : { target_workflow_state_uuid: newWorkflowState.uuid };

          await fetchUpdateWorkflowTransition(
            workflowTransition.uuid,
            updateWorkflowTransitionValues
          );
        } else if (workflowState) {
          await fetchCreateWorkflowTransition({
            workflow_state_uuid: workflowState.uuid,
            target_workflow_state_uuid: newWorkflowState.uuid,
          });
        }
      } else {
        await fetchUpdateWorkflowState(
          workflow.uuid,
          workflowState.uuid,
          workflowStateValues
        );
      }

      await mutateWorkflow();
      await mutateWorkflowState();

      message.success(
        isNew ? "Workflow action created" : "Workflow action updated"
      );

      handleClose();
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await fetchDeleteWorkflowState(workflow.uuid, workflowState.uuid);
      await mutateWorkflow();
      message.success("Workflow action deleted");
      handleClose();
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender
      destroyOnClose
      width="80rem"
      open={open}
      onClose={handleClose}
      afterOpenChange={(open) =>
        !open &&
        navigate(`/dashboard/settings/workflows/${workflowUuid}/editor`)
      }
      title={
        <Typography.Title
          level={5}
          style={{ margin: "0px 5px" }}
          editable={{
            onChange: (value) => {
              form.setFieldValue(WorkflowStateEditorFieldName.Name, value);
            },
          }}
        >
          {actionName}
        </Typography.Title>
      }
      footer={
        <Space>
          <Button
            type="primary"
            disabled={isSaving}
            onClick={() => handleSubmit(form.getFieldsValue())}
            icon={<CloudUploadOutlined />}
          >
            Commit and Save
          </Button>

          {!isNew && (
            <Button danger onClick={handleDelete} disabled={isSaving}>
              Delete
            </Button>
          )}
        </Space>
      }
      loading={isLoading}
    >
      <Spin spinning={isSaving}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="Action Type"
            name={WorkflowStateEditorFieldName.ActionType}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              options={Object.values(WorkflowActionType)
                .filter(
                  (value) =>
                    [
                      WorkflowActionType.Trigger,
                      WorkflowActionType.FindCRMRecord, // Not yet supported
                    ].includes(value) === false
                )
                .map((value) => {
                  return {
                    value,
                    label: getFriendlyWorkflowActionLabel(value),
                  };
                })}
              onChange={setSelectedActionType}
              placeholder="Select an action type..."
            />
          </Form.Item>

          <Form.Item name={WorkflowStateEditorFieldName.Name} hidden />

          <ActionEditor
            selectedActionType={selectedActionType}
            workflowState={isNew ? null : workflowState}
          />
        </Form>
      </Spin>
    </Drawer>
  );
}

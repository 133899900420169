import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useMyIntegrations, useUser } from "../../../../../util/data_hooks";
import { CRM_PROVIDERS } from "../../../../../util/constants";
import { getFriendlyIntegrationLabel } from "../../../../../helpers/label_maps";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowVariableName } from "../../../../../util/enums";
import { CRMProvider } from "../../../../../util/types";

export function CRMSelectFormItem({
  onChange = () => {},
}: {
  onChange?: (crm: CRMProvider) => void;
}) {
  const form = Form.useFormInstance();

  const { user, isLoading: isUserLoading } = useUser();
  const { crmIntegration, isLoading } = useMyIntegrations();

  useEffect(() => {
    if (isUserLoading) return;

    form.setFieldValue(
      [WorkflowStateEditorFieldName.Variables, WorkflowVariableName.CRM],
      user.organization.crm_provider_name
    );

    onChange(user.organization.crm_provider_name);
  }, [user, isLoading]);

  return (
    <Form.Item
      rules={[{ required: true }]}
      label="CRM"
      name={[WorkflowStateEditorFieldName.Variables, WorkflowVariableName.CRM]}
      hidden // Auto select the CRM
    >
      <Select
        placeholder="Select a CRM..."
        loading={isLoading}
        options={CRM_PROVIDERS.map((value) => {
          return {
            label: getFriendlyIntegrationLabel(value),
            value,
            disabled: isLoading ? true : crmIntegration.name !== value,
          };
        })}
        onChange={onChange}
      />
    </Form.Item>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Space, Spin, Typography, message } from "antd";
import {
  fetchCreateWorkflowTransition,
  fetchDeleteWorkflowTransition,
  fetchUpdateWorkflowTransition,
} from "../../../../util/api";
import { WorkflowQueryEditor } from "../action_editors/shared/WorkflowQueryEditor";
import { get } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  useWorkflow,
  useWorkflowTransition,
} from "../../../../util/data_hooks";
import SkeletonInput from "antd/es/skeleton/Input";

enum WorkflowTransitionFieldName {
  Name = "name",
  Conditions = "conditions",
}

interface WorkflowTransitionEditorDrawerProps {
  isNew?: boolean;
}

export function WorkflowTransitionEditorDrawer({
  isNew,
}: WorkflowTransitionEditorDrawerProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { workflowUuid, workflowStateUuid, workflowTransitionUuid } =
    useParams();

  const { mutate: mutateWorkflow } = useWorkflow(workflowUuid);
  const {
    workflowTransition,
    isLoading: isWorkflowTransitionLoading,
    mutate: mutateWorkflowTransition,
  } = useWorkflowTransition(workflowTransitionUuid);

  const [open, setOpen] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [transitionName, setTransitionName] = useState<string>(null);

  useEffect(() => {
    if (isWorkflowTransitionLoading) return;

    if (isNew) {
      form.resetFields();

      const defaultName = "Condition";
      form.setFieldValue(WorkflowTransitionFieldName.Name, defaultName);
      setTransitionName(defaultName);
    } else {
      form.setFieldsValue(workflowTransition);
      setTransitionName(workflowTransition?.name);
    }
  }, [open, isWorkflowTransitionLoading]);

  const handleSubmit = async () => {
    await form.validateFields();

    setIsSaving(true);

    const values = form.getFieldsValue();

    try {
      if (workflowTransition) {
        await fetchUpdateWorkflowTransition(workflowTransition.uuid, values);
      } else {
        await fetchCreateWorkflowTransition({
          ...values,
          workflow_state_uuid: workflowStateUuid,
        });
      }

      await mutateWorkflow();
      await mutateWorkflowTransition();

      message.success(
        workflowTransition ? "Condition updated" : "Condition created"
      );

      setOpen(false);
    } catch (e) {
      message.error(
        get(
          e,
          "response.data.message",
          "Oops, something is wrong on our end! Please try again later."
        )
      );
    }

    setIsSaving(false);
  };

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await fetchDeleteWorkflowTransition(workflowTransition.uuid);
      await mutateWorkflow();
      setOpen(false);
      message.success("Condition deleted");
    } catch (e) {
      message.error(
        get(
          e,
          "response.data.message",
          "Oops, something is wrong on our end! Please try again later."
        )
      );
    }

    setIsSaving(false);
  };

  const showQueryEditor =
    isNew || !!form.getFieldValue(WorkflowTransitionFieldName.Conditions);

  return (
    <Drawer
      forceRender
      destroyOnClose
      width="80rem"
      open={open}
      onClose={() => setOpen(false)}
      afterOpenChange={(open) => !open && navigate(-1)}
      loading={isWorkflowTransitionLoading}
      title={
        isWorkflowTransitionLoading ? (
          <SkeletonInput />
        ) : (
          <Typography.Title
            level={5}
            style={{ margin: "0px 5px" }}
            editable={{
              onChange: (value) => {
                setTransitionName(value);
                form.setFieldValue(WorkflowTransitionFieldName.Name, value);
              },
            }}
          >
            {transitionName}
          </Typography.Title>
        )
      }
      footer={
        <Space>
          <Button type="primary" onClick={handleSubmit} disabled={isSaving}>
            Save
          </Button>

          {!isNew && (
            <Button danger onClick={handleDelete} disabled={isSaving}>
              Delete
            </Button>
          )}
        </Space>
      }
    >
      <Spin spinning={isSaving}>
        <Form layout="vertical" form={form}>
          <Form.Item name={WorkflowTransitionFieldName.Name} hidden />

          {showQueryEditor && (
            <Form.Item
              label="Conditions"
              name={WorkflowTransitionFieldName.Conditions}
            >
              <WorkflowQueryEditor
                fields={[]}
                defaultQuery={form.getFieldValue(
                  WorkflowTransitionFieldName.Conditions
                )}
                onChange={(query) =>
                  form.setFieldValue(
                    WorkflowTransitionFieldName.Conditions,
                    query
                  )
                }
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
}

import React from "react";
import { IntegrationInfo } from "../../util/types";
import { Alert, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getFriendlyIntegrationLabel } from "../../helpers/label_maps";

export function MeetingProviderAlert({
  meetingIntegration,
}: {
  meetingIntegration: IntegrationInfo;
}) {
  const navigate = useNavigate();

  if (!meetingIntegration || meetingIntegration.connected) return null;

  return (
    <Alert
      showIcon
      type="warning"
      message={
        <Typography.Text>
          {getFriendlyIntegrationLabel(meetingIntegration.name)} is not
          connected.{" "}
          <Typography.Link onClick={() => navigate("/dashboard/integrations")}>
            Please connect here
          </Typography.Link>
        </Typography.Text>
      }
    />
  );
}

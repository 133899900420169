import React, { useEffect, useState } from "react";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";
import { Table } from "antd";
import type { TableProps } from "antd";
import _ from "lodash";

interface Props<T> extends TableProps {
  dataSource: T[];
  filteredDataSource: T[];
  columns: TableProps<T>["columns"];
  forceResetTrigger?: number;
  rowKey?: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 1000 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

/**
 *
 * @param dataSource Data to be displayed in the table.
 * @param columns Antd column definition. A draggable icon is added to the columns.
 * @param filteredDataSource Data to be displayed in the table. This is used to filter the dataSource.
 * @param forceResetTrigger Change this prop to force table to be reset to original datasource
 * @param rowKey The key of object that uniquely identifies the row. Default is "uuid"
 * @param other Any other Antd table props are passed through
 * @constructor
 */
export function SearchableTable<T>({
  dataSource,
  filteredDataSource,
  columns,
  forceResetTrigger,
  rowKey = "uuid",
  ...other
}: Props<T>) {
  const [data, setDataSource] = useState(dataSource);

  useEffect(() => {
    setDataSource(dataSource);
  }, [forceResetTrigger]);

  useEffect(() => {
    setDataSource(dataSource);
  }, [dataSource]);

  return (
    <SortableContext
      // rowKey array
      items={data.map((i) => _.get(i, rowKey))}
      strategy={verticalListSortingStrategy}
    >
      <Table
        {...other}
        components={{
          body: { row: Row },
        }}
        columns={columns}
        dataSource={data.filter((o) =>
          filteredDataSource.some(
            (filteredDataSource) =>
              _.get(filteredDataSource, rowKey) === _.get(o, rowKey)
          )
        )}
      />
    </SortableContext>
  );
}

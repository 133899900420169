import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Affix,
  Alert,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import {
  useLayoutedFieldConfigurationGroups,
  useMeeting,
  useMyIntegrations,
  useUser,
} from "../../util/data_hooks";
import {
  fetchRegeneratePredictions,
  triggerWorkflow,
  fetchUpdateCRMRecord,
  fetchUpdateMeeting,
  fetchBulkUpdateAutoGeneratedFields,
} from "../../util/api";
import { meetingsPath } from "../../util/path_builder";
import {
  CRMField,
  CRMRecord,
  LayoutedField,
  LayoutedFieldConfigurationGroup,
} from "../../util/types";
import { DateTag } from "../shared/DateTag";
import { AssociatedCRMRecordModal } from "./AssociatedCRMRecordModal";
import { UserRole, WorkflowTrigger } from "../../util/enums";
import { getFriendlyIntegrationLabel } from "../../helpers/label_maps";
import { CRMIcon } from "../shared/CRMIcon";
import { AppContext } from "../App";
import { MeetingProviderAlert } from "./MeetingProviderAlert";
import { MeetingFormFields } from "./MeetingFormFields";
import { usePageTitle } from "../hooks/usePageTitle";
import { MeetingStatus } from "../shared/MeetingStatus";
import { usePendingAutoGeneratedField } from "./hooks/usePendingAutoGeneratedField";

const getPendingFields = (
  groups: LayoutedFieldConfigurationGroup[]
): LayoutedField[] =>
  groups
    .flatMap(({ layouted_fields }) => layouted_fields)
    .filter(({ pending_review }) => pending_review);

export function MeetingForm() {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [form] = Form.useForm();
  const { meetingId } = useParams();

  const [associatedCRMRecordModalOpen, setAssociatedCRMRecordModalOpen] =
    useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { user, isLoading: isUserLoading } = useUser();
  const { meetingIntegrations, isLoading: isIntegrationsLoading } =
    useMyIntegrations();

  const {
    meeting,
    mutate: mutateMeeting,
    isLoading: isMeetingLoading,
    error: isMeetingError,
  } = useMeeting(meetingId);

  usePageTitle(meeting?.topic);

  const {
    layoutedFieldConfigurationGroups,
    isLoading: isLayoutedFieldConfigurationGroupsLoading,
    mutate: mutateLayoutedFieldConfigurationGroups,
    error: isLayoutedFieldConfigurationGroupsError,
  } = useLayoutedFieldConfigurationGroups(meetingId, {
    requestPredictions: true,
    fieldStatus: "visible",
  });

  const { scrollToPendingAutoGeneratedField } = usePendingAutoGeneratedField(
    layoutedFieldConfigurationGroups
  );

  const pendingAssociations = layoutedFieldConfigurationGroups.filter(
    ({ pending_association }) => pending_association
  );

  const ignoredGroups = layoutedFieldConfigurationGroups.filter(
    ({ is_ignored }) => is_ignored
  );

  const crmManagedGroups = layoutedFieldConfigurationGroups.filter(
    ({ field_configuration_group }) =>
      !!field_configuration_group.synced_crm_object
  );

  const meetingIntegration = meetingIntegrations.find(
    ({ name }) => name === meeting?.provider_name
  );

  const regenerationAllowed =
    user?.roles?.includes(UserRole.SuperAdmin) ||
    ["development", "staging"].includes(context.environment);

  const crmProviderName = user?.organization?.crm_provider_name;

  const isGeneratingPredictions =
    meeting?.predictions_requested_at && !meeting?.predictions_completed_at;

  const isAppending =
    meeting?.append_requested_at && !meeting?.append_completed_at;

  const isLoading =
    isUserLoading ||
    isMeetingLoading ||
    isLayoutedFieldConfigurationGroupsLoading ||
    isIntegrationsLoading;

  const isError = isMeetingError || isLayoutedFieldConfigurationGroupsError;

  useEffect(() => {
    if (isLoading || isGeneratingPredictions || isAppending || isError) return;

    mutateLayoutedFieldConfigurationGroups();

    if (meeting && meeting.predictions_completed_at) {
      fetchUpdateMeeting(meeting.uuid, { reviewed_at: new Date() });
    }
  }, [isLoading, isGeneratingPredictions, isAppending]);

  useEffect(() => {
    if (!isAppending) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isAppending]);

  useEffect(() => {
    mutateMeeting();
  }, [layoutedFieldConfigurationGroups]);

  useEffect(() => {
    if (
      isLoading ||
      isError ||
      isGeneratingPredictions ||
      pendingAssociations.length === 0
    ) {
      return;
    }

    setAssociatedCRMRecordModalOpen(true);
  }, [
    isLoading,
    isGeneratingPredictions,
    isAppending,
    isError,
    meeting,
    layoutedFieldConfigurationGroups,
  ]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const updateStatusMessages: {
      status: "success" | "error";
      objectLabel: string;
      message?: string;
    }[] = [];

    for (const group of layoutedFieldConfigurationGroups) {
      const syncedCRMObject = group.field_configuration_group.synced_crm_object;
      const associatedCrmRecord = group.associated_crm_record;
      const crmRecord = group.crm_record;
      const isIgnored = group.is_ignored;
      const fieldConfigurationGroup = group.field_configuration_group;

      if (!syncedCRMObject || !associatedCrmRecord || !crmRecord || isIgnored) {
        continue;
      }

      const updatedCRMRecord: CRMRecord = {
        crm: crmRecord.crm,
        crm_object_type: crmRecord.crm_object_type,
        crm_record_name: crmRecord.crm_record_name,
        crm_record_id: crmRecord.crm_record_id,
        crm_record_fields: crmRecord.crm_record_fields.map(
          (crmField: CRMField) => {
            const value = form.getFieldValue([
              fieldConfigurationGroup.uuid,
              crmField.crm_field_name,
            ]);

            return {
              ...crmField,
              crm_field_value: value,
            };
          }
        ),
      };

      try {
        await fetchUpdateCRMRecord({
          objectType: crmRecord.crm_object_type,
          recordNameProperty: syncedCRMObject.searchable_crm_field_name,
          recordId: crmRecord.crm_record_id,
          crmRecord: updatedCRMRecord,
          associatedCRMRecordUuid: associatedCrmRecord.uuid,
          meetingUuid: meetingId,
        });

        updateStatusMessages.push({
          status: "success",
          objectLabel: syncedCRMObject.crm_object_label,
        });
      } catch (error) {
        updateStatusMessages.push({
          status: "error",
          objectLabel: syncedCRMObject.crm_object_label,
          message: error.response.data.message,
        });
      }
    }

    const errorMessages = updateStatusMessages.filter(
      ({ status }) => status === "error"
    );

    if (errorMessages.length === 0) {
      if (user.workflows_enabled) {
        try {
          await triggerWorkflow(WorkflowTrigger.MeetingSaved, {
            meeting_uuid: meetingId,
          });
        } catch (e) {
          message.error(
            `Error triggering workflows: ${
              e.response ? e.response.data.message : e.message
            }`
          );
        }
      }

      Modal.success({
        width: 500,
        title: `${getFriendlyIntegrationLabel(
          crmProviderName
        )} Synced Successfully! 🎉`,
        content: "Would you like to update another meeting?",
        okText: "Update Another Meeting",
        cancelText: "Keep Reviewing",
        onOk: () => navigate("/dashboard/meetings"),
        okCancel: true,
      });
    } else {
      Modal.warn({
        width: 600,
        title: `An error occurred while syncing to ${getFriendlyIntegrationLabel(
          crmProviderName
        )}`,
        content: (
          <Row gutter={[12, 14]}>
            <Col span={24}>
              <Typography.Text>
                Please review and resolve the following errors:
              </Typography.Text>
            </Col>

            {updateStatusMessages.map(({ status, objectLabel, message }, i) => (
              <Col key={i} span={24}>
                {status === "error" && (
                  <Alert
                    showIcon
                    type="error"
                    message={
                      <>
                        <b>Error updating {objectLabel}:</b> {message}
                      </>
                    }
                  />
                )}

                {status === "success" && (
                  <Alert
                    showIcon
                    type="success"
                    message={<b>{objectLabel} synced successfully</b>}
                  />
                )}
              </Col>
            ))}
          </Row>
        ),
        okText: "Close",
      });
    }

    mutateMeeting();
    mutateLayoutedFieldConfigurationGroups();

    setIsSubmitting(false);
  };

  const validationMessages = {
    required: "${label} is required!",
    number: {
      len: "${label} must be equal to ${len}",
      min: "${label} must be minimum ${min}",
      max: "${label} must be maximum ${max}",
      range: "${label} must be between ${min}-${max}",
    },
  };

  if (isLoading) return <Skeleton active />;

  return (
    <Spin
      spinning={isGeneratingPredictions || isAppending}
      tip={
        <>
          {isGeneratingPredictions && (
            <Space direction="vertical" style={{ marginTop: 20 }}>
              <Typography.Text>
                Hang tight, Swyft is automatically generating meeting details...
              </Typography.Text>

              <Typography.Text>
                {`We'll notify you when they're complete!`}
              </Typography.Text>
            </Space>
          )}
          {isAppending && (
            <Space direction="vertical" style={{ marginTop: 20 }}>
              <Typography.Text>
                Hang tight, Swyft is intelligently combining insights with your
                CRM data...
              </Typography.Text>

              <Typography.Text>
                {`This might take up to a minute!`}
              </Typography.Text>
            </Space>
          )}
        </>
      }
    >
      <AssociatedCRMRecordModal
        open={associatedCRMRecordModalOpen}
        meeting={meeting}
        layoutedFieldConfigurationGroups={layoutedFieldConfigurationGroups.filter(
          ({ field_configuration_group }) =>
            !!field_configuration_group.synced_crm_object
        )}
        hideReviewedRecords
        mutateLayoutedFieldConfigurationGroups={
          mutateLayoutedFieldConfigurationGroups
        }
        onClose={() => {
          setAssociatedCRMRecordModalOpen(false);
          if (!isAppending) scrollToPendingAutoGeneratedField();
        }}
      />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        <MeetingProviderAlert meetingIntegration={meetingIntegration} />

        <Row align="top" justify="space-between" style={{ marginBottom: 15 }}>
          <Col span={12}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Breadcrumb
                  items={[
                    {
                      title: (
                        <a onClick={() => navigate(meetingsPath())}>
                          My Meetings
                        </a>
                      ),
                    },
                    { title: meeting.topic },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Typography.Title
                  level={3}
                  style={{ margin: 0 }}
                  editable={{
                    onChange: async (value) => {
                      await fetchUpdateMeeting(meetingId, {
                        user_modified_topic: value,
                      });

                      mutateLayoutedFieldConfigurationGroups();
                    },
                  }}
                >
                  {meeting.topic}
                </Typography.Title>
              </Col>

              <Col span={24}>
                <Space size="small">
                  <DateTag dateTime={meeting.start_time} />
                  <MeetingStatus meeting={meeting} />
                </Space>
              </Col>
            </Row>
          </Col>

          <Col>
            {regenerationAllowed && (
              <Button
                type="primary"
                disabled={
                  !meetingIntegration?.connected && !meeting.is_manual_upload
                }
                onClick={async () => {
                  await fetchRegeneratePredictions(meeting.uuid);
                  await mutateMeeting();
                  mutateLayoutedFieldConfigurationGroups();
                }}
              >
                Regenerate Predictions
              </Button>
            )}
          </Col>
        </Row>

        <Form
          form={form}
          name="MeetingForm"
          initialValues={{ remember: true }}
          onFinish={() => {
            const pendingFields = getPendingFields(
              layoutedFieldConfigurationGroups
            );

            if (pendingFields.length > 0) {
              Modal.confirm({
                title: `${
                  pendingFields.length === 1
                    ? "1 Field Is"
                    : `${pendingFields.length} Fields Are`
                } Pending Review`,
                content: "Would you like to approve all pending fields?",
                okText: `Approve All and Sync`,
                cancelText: "Keep Reviewing",
                onCancel: scrollToPendingAutoGeneratedField,
                onOk: async () => {
                  setIsSubmitting(true);

                  await fetchBulkUpdateAutoGeneratedFields(
                    meetingId,
                    pendingFields.map(
                      ({ auto_generated_field }) => auto_generated_field.uuid
                    ),
                    { accepted: true }
                  );

                  await mutateLayoutedFieldConfigurationGroups();

                  // Wait for approved field values to be set
                  await new Promise((r) => setTimeout(r, 250));

                  await handleSubmit();
                },
                width: "30rem",
              });
            } else {
              handleSubmit();
            }
          }}
          layout="vertical"
          scrollToFirstError
          validateMessages={validationMessages}
          style={{ marginBottom: "6rem" }}
        >
          <>
            {layoutedFieldConfigurationGroups.map((group) => {
              return (
                <Col span={24} key={group.field_configuration_group.uuid}>
                  <MeetingFormFields
                    meeting={meeting}
                    layoutedFieldConfigurationGroup={group}
                    mutateLayoutedFieldConfigurationGroups={
                      mutateLayoutedFieldConfigurationGroups
                    }
                  />

                  <Divider />
                </Col>
              );
            })}
          </>

          {!isLoading && (
            <Affix offsetBottom={15} key="affix">
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    boxShadow: "8px 5px 5px rgba(140, 140, 140, 0.25)",
                  }}
                  icon={<CRMIcon crm={crmProviderName} />}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  disabled={
                    isLoading ||
                    pendingAssociations.length > 0 ||
                    ignoredGroups.length === crmManagedGroups.length
                  }
                >
                  {`Sync to ${getFriendlyIntegrationLabel(crmProviderName)}`}
                </Button>
              </Form.Item>
            </Affix>
          )}
        </Form>
      </Space>
    </Spin>
  );
}

import React from "react";
import { Tag, Tooltip } from "antd";
import { CRMProvider } from "../../util/types";
import { CRMIcon, getCRMColor } from "./CRMIcon";

function ConditionalLink({
  url,
  children,
}: {
  url: string;
  children: React.ReactNode;
}) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return children;
}

function ConditionalTag({
  color,
  children,
}: {
  color: string;
  children: React.ReactNode;
}) {
  if (color) {
    return (
      <Tag color={color} style={{ margin: 0 }}>
        {children}
      </Tag>
    );
  }

  return children;
}

interface CRMRecordTagProps {
  crm: CRMProvider;
  name: string;
  url?: string;
  autoAssociated?: boolean;
  showBackground?: boolean;
}

export function CRMRecordTag({
  crm,
  name,
  url,
  autoAssociated,
  showBackground,
}: CRMRecordTagProps) {
  if (!name) return null;

  const crmColor = getCRMColor(crm);

  const maxLength = 45;
  const truncatedName =
    name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;

  return (
    <ConditionalLink url={url}>
      <ConditionalTag color={showBackground ? crmColor : null}>
        <Tooltip title={autoAssociated ? "Auto Associated" : null}>
          <CRMIcon crm={crm} color={showBackground ? "white" : crmColor} />{" "}
          {truncatedName}
        </Tooltip>
      </ConditionalTag>
    </ConditionalLink>
  );
}

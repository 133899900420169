import React from "react";
import { FieldDefinitionWithUsage, Team } from "../../../../../util/types";
import {
  Button,
  Drawer,
  Flex,
  Form,
  message,
  Modal,
  Select,
  theme,
  Typography,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { get } from "lodash";
import {
  deleteFieldConfiguration,
  updateFieldConfigurationTeams,
} from "../../../../../util/api";
import { teamColors } from "../../../../../helpers/teamColorHelper";
import { useOrganizationFieldConfiguration } from "../../../../../util/data_hooks";
import { teamSelectTagRender } from "../../../../shared/teamSelectTagRender";

interface Props {
  fieldDefinition: FieldDefinitionWithUsage;
  fieldConfigurationUuid: string;
  allTeams: Team[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
}

export function EditFieldConfigurationDrawer({
  fieldDefinition,
  fieldConfigurationUuid,
  allTeams,
  isOpen,
  setIsOpen,
  onEdit,
  onDelete,
}: Props) {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [formDirty, setFormDirty] = React.useState(false);
  const assignedTeamColors = teamColors(allTeams);
  const { fieldConfiguration, isLoading } = useOrganizationFieldConfiguration(
    fieldConfigurationUuid
  );
  const teamOptions = allTeams.map((team) => ({
    value: team.uuid,
    label: team.name,
    disabled:
      fieldConfiguration?.automated && team.field_automations_available <= 0,
  }));

  const selectedFieldConfigTeams = teamOptions.filter((team) =>
    fieldDefinition.configuration_usage[fieldConfigurationUuid].includes(
      team.value
    )
  );

  const updateTeams = async (values) => {
    try {
      await updateFieldConfigurationTeams(fieldConfigurationUuid, values.teams);
      message.success("Field configuration updated!");
      onEdit();
      closeDrawer();
    } catch (err) {
      const defaultMessage =
        "Oops, something is wrong on our end! Please try again later.";

      const is500 = get(err, "response.status", 500) >= 500;

      message.error(
        is500 ? defaultMessage : get(err, "response.data.error", defaultMessage)
      );
    }
  };

  const closeDrawer = () => {
    setIsOpen(false);
    form.resetFields();
    setFormDirty(false);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Delete Field Configuration?`,
      icon: <DeleteOutlined style={{ color: token.colorError }} />,
      content:
        "Field configuration will be deleted. Workflows depending on this field may not function correctly.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteFieldConfiguration(fieldConfigurationUuid);
          message.success("Field configuration deleted!");
          onDelete();
          closeDrawer();
        } catch (err) {
          const defaultMessage =
            "Oops, something is wrong on our end! Please try again later.";

          const is500 = get(err, "response.status", 500) >= 500;

          message.error(
            is500
              ? defaultMessage
              : get(err, "response.data.error", defaultMessage)
          );
        }
      },
    });
  };

  return (
    <Drawer
      destroyOnClose
      title={"Edit Field Configuration Assignments"}
      placement="right"
      width="50rem"
      open={isOpen}
      onClose={closeDrawer}
      extra={
        <Button danger onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
          Delete{" "}
        </Button>
      }
      loading={isLoading}
    >
      <Form
        initialValues={{
          teams: selectedFieldConfigTeams,
        }}
        layout={"vertical"}
        form={form}
        onValuesChange={() => setFormDirty(true)}
        onFinish={updateTeams}
      >
        <Form.Item label={"Teams"} name={"teams"}>
          <Select
            tagRender={teamSelectTagRender(assignedTeamColors)}
            mode="multiple"
            placeholder={"Select Teams"}
            style={{ width: "100%" }}
            options={teamOptions}
            optionRender={(teamOption) => (
              <Flex>
                {teamOption.label}
                <Typography.Text
                  type={"secondary"}
                  style={{ marginLeft: "auto", marginRight: "2rem" }}
                >
                  Automations remaining:{" "}
                  {
                    allTeams.find((team) => team.uuid === teamOption.value)
                      .field_automations_available
                  }
                </Typography.Text>
              </Flex>
            )}
            allowClear
          />
        </Form.Item>

        <Flex justify={"flex-end"}>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!formDirty}>
              Save
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Drawer>
  );
}

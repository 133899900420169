import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useCRMObjects } from "../../../../../util/data_hooks";
import { CRMObject, CRMProvider } from "../../../../../util/types";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowVariableName } from "../../../../../util/enums";

interface CRMObjectSelectFormItemProps {
  crm: CRMProvider;
  name?: (WorkflowStateEditorFieldName | WorkflowVariableName)[];
  label?: string;
  required?: boolean;
  onChange?: (crmObject: CRMObject) => void;
}

export function CRMObjectSelectFormItem({
  crm,
  name = [
    WorkflowStateEditorFieldName.Variables,
    WorkflowVariableName.CRMObjectType,
  ],
  label = "Object type",
  required = true,
  onChange = () => {},
}: CRMObjectSelectFormItemProps) {
  const form = Form.useFormInstance();
  const { objects, isLoading } = useCRMObjects(crm);

  const handleOnChange = (value) => {
    const crmObject = objects.find(
      (object) => object.crm_object_name === value
    );

    onChange(crmObject);
  };

  useEffect(() => {
    handleOnChange(form.getFieldValue(name));
  }, [crm, isLoading]);

  return (
    <Form.Item rules={[{ required }]} label={label} name={name}>
      <Select
        allowClear
        showSearch
        placeholder="Select an object type..."
        options={objects.map(({ crm_object_label, crm_object_name }) => {
          return {
            label: `${crm_object_label} (${crm_object_name})`,
            value: crm_object_name,
          };
        })}
        onChange={(value) => {
          form.setFieldValue(
            [
              WorkflowStateEditorFieldName.Variables,
              WorkflowVariableName.CRMRecordId,
            ],
            null
          );
          handleOnChange(value);
        }}
      />
    </Form.Item>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Select, Typography } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CRMField, WorkflowState } from "../../../../../util/types";
import { WorkflowStateEditorFieldName } from "../../shared/WorkflowStateEditorDrawer";
import {
  CRMFieldType,
  WorkflowActionType,
  WorkflowVariableName,
} from "../../../../../util/enums";
import { DynamicVariableTextarea } from "./dynamic_variables/DynamicVariableTextarea";
import { DynamicVariableSelect } from "./dynamic_variables/DynamicVariableSelect";
import { isNullOrUndefined } from "../../../../../helpers/isNullOrUndefined";

interface CRMFieldValuesFormItemProps {
  workflowState: WorkflowState;
  objectFields: CRMField[];
  showRequiredFields?: boolean;
}

export function CRMFieldValuesFormItem({
  workflowState,
  objectFields,
  showRequiredFields,
}: CRMFieldValuesFormItemProps) {
  const [fieldsToUpdate, setFieldsToUpdate] = useState<CRMField[]>([]);
  const [newFieldName, setNewFieldName] = useState<string>(null);
  const [newFieldModalOpen, setNewFieldModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fields = objectFields.filter(
      ({ crm_field_name, crm_field_required }) => {
        if (showRequiredFields && crm_field_required) return true;
        if (!workflowState?.variables?.crm_field_values) return false;

        return Object.keys(workflowState.variables.crm_field_values).includes(
          crm_field_name
        );
      }
    );

    setFieldsToUpdate(fields);
  }, [objectFields]);

  const handleModalClose = () => {
    setNewFieldName(null);
    setNewFieldModalOpen(false);
  };

  return (
    <>
      <Modal
        destroyOnClose
        open={newFieldModalOpen}
        onCancel={handleModalClose}
        title="Add Field"
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              const isPresentInFieldsToUpdate = fieldsToUpdate
                .map(({ crm_field_name }) => crm_field_name)
                .includes(newFieldName);

              if (!isPresentInFieldsToUpdate) {
                const newField = objectFields.find(
                  ({ crm_field_name }) => crm_field_name === newFieldName
                );

                setFieldsToUpdate([...fieldsToUpdate, newField]);
              }

              handleModalClose();
            }}
            disabled={!newFieldName}
          >
            Add Field
          </Button>,
        ]}
      >
        <Select
          ref={(input) => input && input.focus()}
          defaultOpen
          value={newFieldName}
          optionFilterProp="label"
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a CRM field..."
          options={objectFields.map(({ crm_field_label, crm_field_name }) => {
            return {
              label: `${crm_field_label} (${crm_field_name})`,
              value: crm_field_name,
            };
          })}
          onSelect={setNewFieldName}
        />
      </Modal>

      {fieldsToUpdate.map((crmField) => (
        <CRMFieldValueCard
          key={crmField.crm_field_name}
          crmField={crmField}
          showRequiredFields={showRequiredFields}
          onRemove={() =>
            setFieldsToUpdate(
              fieldsToUpdate.filter(
                (field) => field.crm_field_name !== crmField.crm_field_name
              )
            )
          }
        />
      ))}

      <Button
        icon={<PlusCircleOutlined />}
        onClick={() => setNewFieldModalOpen(true)}
      >
        Add Field
      </Button>
    </>
  );
}

function CRMFieldValueCard({
  crmField,
  showRequiredFields,
  onRemove,
}: {
  crmField: CRMField;
  showRequiredFields: boolean;
  onRemove: () => void;
}) {
  const form = Form.useFormInstance();

  const {
    crm_field_name,
    crm_field_label,
    crm_field_type,
    crm_field_required,
    crm_field_picklist_values,
  } = crmField;

  const isRequired = showRequiredFields && crm_field_required;

  const showOverwriteOptions =
    crm_field_type !== CRMFieldType.Boolean &&
    [WorkflowActionType.UpdateCRMRecord].includes(
      form.getFieldValue(WorkflowStateEditorFieldName.ActionType)
    );

  let staticOptions = [];

  switch (crm_field_type) {
    case CRMFieldType.Picklist:
    case CRMFieldType.Multipicklist:
    case CRMFieldType.Reference:
      staticOptions = crm_field_picklist_values.map(({ label, value }) => {
        return { label, value };
      });
      break;
    case CRMFieldType.Boolean:
      staticOptions = [
        { label: "True", value: true },
        { label: "False", value: false },
      ];
  }

  const legacyFieldValueName = [
    WorkflowStateEditorFieldName.Variables,
    WorkflowVariableName.CRMFieldValues,
    crm_field_name,
  ];

  const newFieldValueName = [
    WorkflowStateEditorFieldName.Variables,
    WorkflowVariableName.CRMFieldValues,
    crm_field_name,
    WorkflowVariableName.Value,
  ];

  const isLegacyField =
    typeof form.getFieldValue(legacyFieldValueName) !== "object" &&
    !isNullOrUndefined(form.getFieldValue(legacyFieldValueName));

  const crmFieldValueFieldName = isLegacyField
    ? legacyFieldValueName
    : newFieldValueName;

  return (
    <Card
      style={{ marginBottom: 20 }}
      size="small"
      title={
        <Row justify="space-between">
          <Col>
            <Row gutter={[12, 0]} align="middle">
              <Col>
                <Typography.Text>
                  {crm_field_label}
                  {crm_field_label !== crm_field_name
                    ? ` (${crm_field_name})`
                    : ""}
                </Typography.Text>
              </Col>

              {showOverwriteOptions && !isLegacyField && (
                <Col>
                  <Form.Item
                    style={{ margin: 0 }}
                    initialValue={true}
                    name={[
                      WorkflowStateEditorFieldName.Variables,
                      WorkflowVariableName.CRMFieldValues,
                      crm_field_name,
                      WorkflowVariableName.OverwriteExistingValue,
                    ]}
                  >
                    <Select
                      popupMatchSelectWidth={false}
                      size="small"
                      options={[
                        { label: "Overwrite", value: true },
                        { label: "Write if empty", value: false },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>

          {!isRequired && (
            <Col>
              <Button
                danger
                type="text"
                size="small"
                key="remove-field"
                icon={<CloseCircleOutlined />}
                onClick={onRemove}
              >
                Remove
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <Form.Item
        style={{ marginBottom: 0 }}
        rules={[{ required: isRequired, message: "This field is required" }]}
        name={crmFieldValueFieldName}
      >
        {[
          CRMFieldType.Id,
          CRMFieldType.Boolean,
          CRMFieldType.Picklist,
          CRMFieldType.Multipicklist,
          CRMFieldType.Reference,
        ].includes(crm_field_type) ? (
          <DynamicVariableSelect
            fieldType={crm_field_type}
            staticOptions={staticOptions}
            value={form.getFieldValue(crmFieldValueFieldName)}
            onChange={(value) => {
              form.setFieldValue(crmFieldValueFieldName, value);
            }}
          />
        ) : (
          <DynamicVariableTextarea
            showBorder={false}
            value={form.getFieldValue(crmFieldValueFieldName)}
            onChange={(value) => {
              form.setFieldValue(crmFieldValueFieldName, value);
            }}
          />
        )}
      </Form.Item>
    </Card>
  );
}

import { configureStore } from "@reduxjs/toolkit";

import teamReducer from "./features/team/teamSlice";
import playgroundReducer from "./features/PlaygroundFieldTesting/playgroundSlice";
import fieldConfigurationFilterReducer from "./features/FieldConfigurationFilters/fieldConfigurationFilterSlice";
import teamDrawerTabReducer from "./features/team/teamDrawerTab";

export const store = configureStore({
  reducer: {
    team: teamReducer,
    playground: playgroundReducer,
    teamDrawerTab: teamDrawerTabReducer,
    fieldConfigurationFilter: fieldConfigurationFilterReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
